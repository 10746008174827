import React, { useState } from "react";
import { Button, Grid, Header, Image, Label, Segment, Transition } from "semantic-ui-react";
import { ReactComponent as SaveIcon } from "../../../../assets/Icons/check-experience.svg";
import { ReactComponent as AddExperienceIcon } from "../../../../assets/Icons/add-experience.svg";
import defaultImage from "../../../../assets/Images/defaultImage.jpg";
import "./UserProfileImageMessage.css";

const UserProfileImagePicker = ({ userProfile, open, handleCloseMessage, handleOnImageChange }) => {
  const duration = 500;
  const animation = "drop";
  const [imageUrl, setImageUrl] = useState("");
  const [imageFile, setImageFile] = useState();

  const onImageChange = (event) => {
    if (!!event.target.files[0]) {
      const file = event.target.files[0];
      const url = URL.createObjectURL(file);
      setImageUrl(url);
      setImageFile(file);
    }
  };

  const saveImage = async () => {
    if (imageFile) {
      const file = {
        avatar: URL.createObjectURL(imageFile),
        avatarBase64: await fromFileToBase64(imageFile),
      };
      handleOnImageChange(file);
      setImageUrl("");
      setImageFile();
    }
  };

  const fromFileToBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (readerEvt) => {
        resolve(readerEvt.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  return (
    <Transition visible={open} animation={animation} duration={duration}>
      <Segment
        style={{
          position: "fixed",
          top: "0",
          left: "0",
          width: "100%",
          height: "100%",
          backgroundColor: "transparent",
          zIndex: "9999",
          margin: "0px",
        }}
      >
        <Segment className="user-profile-image-message-card">
          <Header className="user-profile-image-message-title">Upload your profile picture</Header>
          <Grid.Column className="user-profile-image-message-image-container">
            <input
              type="file"
              id="file"
              accept="image/*"
              name="image"
              style={{ display: "none" }}
              onChange={onImageChange}
            />
            <Image
              className="user-profile-image-message-image"
              circular
              as="label"
              htmlFor="file"
              src={imageUrl ? imageUrl : userProfile.avatar ? userProfile.avatar : defaultImage}
            />
            <Button
              icon
              as="label"
              htmlFor="file"
              type="button"
              className="user-profile-image-message-image-add-button"
            >
              <i aria-hidden="true" className="icon right user-profile-image-message-image-add-button-icon">
                <AddExperienceIcon />
              </i>
            </Button>
          </Grid.Column>
          <Grid.Row>
            <Button
              icon
              labelPosition="right"
              type="button"
              className="user-profile-image-message-button"
              onClick={() => saveImage()}
            >
              Save
              <i
                aria-hidden="true"
                className="icon right
                                user-profile-image-message-button-icon"
              >
                <SaveIcon />
              </i>
            </Button>
            <Label className="user-profile-image-message-back-button" onClick={() => handleCloseMessage()}>
              Cancel
            </Label>
          </Grid.Row>
        </Segment>
      </Segment>
    </Transition>
  );
};

export default UserProfileImagePicker;
