import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button, Card, Grid, Search } from "semantic-ui-react";
import ExperienceCardItemAdd from "./CardItemAdd";
import {
  clearExperiences,
  loadExperiences,
  selectHasMoreExperiences,
  suggestExperiences,
  toggleExperiencesFiltersInReview,
  updateExperiencesFiltersQuery,
} from "../../../../redux/experienceDucks";
import "./CardList.css";
import ExperienceCardItem from "./CardItem";

const fromTitlesToResults = (titles) => {
  // If no titles, we return an empty array.
  if (!titles) {
    return [];
  }
  // For each title,
  // Map the title to the title of the result.
  return titles.map((title) => ({
    title: title,
    description: "",
  }));
};

const ExperienceCardList = ({ setOpenMessage }) => {
  const dispatch = useDispatch();

  const experiences = useSelector((store) => store.experiences.experiences);
  const filterInReview = useSelector((store) => store.experiences.filterInReview);
  const filterQuery = useSelector((store) => store.experiences.filterQuery);
  const hasMoreExperiences = useSelector(selectHasMoreExperiences);
  const suggestExperienceTitles = useSelector((store) => store.experiences.suggestExperienceTitles);

  const [searchSuggestions, setSearchSuggestions] = useState([]);

  const endOfPage = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (!entries[0].isIntersecting) {
          return;
        }
        if (experiences && !hasMoreExperiences) {
          return;
        }
        dispatch(loadExperiences());
      },
      {
        threshold: 1.0,
      },
    );
    if (endOfPage.current) {
      observer.observe(endOfPage.current);
    }
    return () => {
      if (endOfPage.current) {
        observer.unobserve(endOfPage.current);
      }
    };
  }, [dispatch, experiences, endOfPage, hasMoreExperiences]);

  useEffect(() => {
    setSearchSuggestions(fromTitlesToResults(suggestExperienceTitles));
  }, [suggestExperienceTitles]);

  const handleSearchChange = (event) => {
    // Get the query.
    const query = event.target.value;
    // Update the query in the store.
    dispatch(updateExperiencesFiltersQuery(query));
    // If the query is longer than 2 characters.
    // Then, retrieve the search suggestions.
    if (query && query.length >= 3) {
      dispatch(suggestExperiences(query));
    }
  };

  const handleSearchKeyDown = (event) => {
    // If the key is not Enter, we don't do anything.
    if (event.key !== "Enter") {
      return;
    }
    // Get the query.
    const query = event.target.value;
    // If the query is not empty,
    // And, the query not longer than 2 characters,
    // Then, we don't do anything.
    if (query && query.length < 3) {
      return;
    }
    // Clear the experiences.
    dispatch(clearExperiences());
  };

  const handleInReviewClick = () => {
    dispatch(toggleExperiencesFiltersInReview());
  };

  const handleResultSelect = (event, data) => {
    // Get the title of the result.
    const title = data.result.title;
    // Update the query in the store.
    dispatch(updateExperiencesFiltersQuery(title));
    // Clear the experiences.
    dispatch(clearExperiences());
  };

  return (
    <Grid
      style={{
        margin: 0,
        padding: 0,
        height: "calc(100vh - 80px)",
      }}
    >
      <Grid.Row
        style={{
          height: 72,
          padding: 0,
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0px 14px",
          }}
        >
          <Search
            className={"experience-card-list-search"}
            fluid
            onSearchChange={handleSearchChange}
            onKeyDown={handleSearchKeyDown}
            onResultSelect={handleResultSelect}
            placeholder="Search..."
            results={searchSuggestions}
            size="big"
            showNoResults={false}
            style={{
              width: "428px",
              margin: 0,
            }}
            value={filterQuery}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Button
            circular
            color={filterInReview ? "teal" : null}
            onClick={handleInReviewClick}
            size="big"
            style={{
              fontSize: "14px",
            }}
          >
            In Review
          </Button>
        </div>
      </Grid.Row>
      <Grid.Row
        style={{
          height: "calc(100vh - 80px - 72px)",
          padding: 0,
          overflowY: "scroll",
          overflowX: "hidden",
        }}
      >
        {/*
                We wrap the Card.Group to adjust the height of the group dynamically.
                Otherwise, the group expands to the full height of the parent.
                */}
        <div>
          <Card.Group
            itemsPerRow={6}
            style={{
              margin: "0px 14px",
            }}
          >
            <ExperienceCardItemAdd />
            {experiences?.map((item) => (
              <ExperienceCardItem experience={item} key={item.value.id.value} setOpenMessage={setOpenMessage} />
            ))}
            <div ref={endOfPage} />
          </Card.Group>
        </div>
      </Grid.Row>
    </Grid>
  );
};

export default ExperienceCardList;
