import { getAxios } from "../services/Axios";
import { getTopicsUrl } from "../constants/url";
import { handleError } from "./appDucks";

const INITIAL_STATE = {
  topics: null,
  loading: false,
};

const ACTIONS = {
  UPDATE_TOPICS: "TOPICS_UPDATE_TOPICS",
  START_LOADING: "TOPICS_START_LOADING",
  STOP_LOADING: "TOPICS_STOP_LOADING",
};

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case ACTIONS.UPDATE_TOPICS:
      return { ...state, topics: action.payload };
    case ACTIONS.START_LOADING:
      return { ...state, loading: true };
    case ACTIONS.STOP_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
}

function fromMessageToTopic(message) {
  return {
    id: message.id,
    name: message.name,
  };
}

export const getTopics = () => async (dispatch) => {
  await dispatch({ type: ACTIONS.START_LOADING });
  let topics;
  try {
    const params = {
      page_size: 500,
    };
    const response = await getAxios(getTopicsUrl, params);
    topics = response.data.entries.map(fromMessageToTopic);
    await dispatch({ type: ACTIONS.UPDATE_TOPICS, payload: topics });
  } catch (e) {
    dispatch(handleError(e));
  } finally {
    await dispatch({ type: ACTIONS.STOP_LOADING });
  }
};
