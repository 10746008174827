import React from "react";
import { withRouter } from "react-router-dom";
import { Container } from "semantic-ui-react";
import "./EmailVerified.css";
import NavBar from "../../components/NavBar";

const EmailVerified = (props) => {
  return (
    <Container className="background">
      <NavBar landing={false} />
      <Container className="text-container" textAlign="center">
        Email verified!
      </Container>
    </Container>
  );
};

export default withRouter(EmailVerified);
