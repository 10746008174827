import React from "react";
import { Button, Grid, Header, Segment, Transition } from "semantic-ui-react";
import "./BlockAccount.css";

const LoginBlockAccount = ({ open, handleCloseMessage }) => {
  const duration = 1000;
  const animation = "drop";

  return (
    <Transition visible={open} animation={animation} duration={duration}>
      <Segment className="block-account-container">
        <Segment className="block-account-card">
          <Header className="block-account-title">Cuenta Bloqueada</Header>
          <p className="block-account-sub-title">
            Múltiplos intentos detectados, ingresa a tu correo y da click en el link para recuperar tu contraseña.
          </p>
          <Grid.Row>
            <Button className="block-account-button" onClick={() => handleCloseMessage()}>
              Entendido
            </Button>
          </Grid.Row>
        </Segment>
      </Segment>
    </Transition>
  );
};

export default LoginBlockAccount;
