import React from "react";
import { useSelector } from "react-redux";
import { Dimmer, Loader } from "semantic-ui-react";

const Loading = () => {
  const deletingCard = useSelector((store) => store.creditCards.deleting);
  const loadingAuth = useSelector((store) => store.authentication.loading);
  const loadingCard = useSelector((store) => store.creditCards.loading);
  const loadingExperience = useSelector((store) => store.experiences.loading);
  const loadingProfile = useSelector((store) => store.profile.loading);
  const loadingTopics = useSelector((store) => store.topics.loading);
  const savingCard = useSelector((store) => store.creditCards.saving);

  const active = () => {
    return !!(
      deletingCard ||
      loadingAuth ||
      loadingCard ||
      loadingExperience ||
      loadingProfile ||
      loadingTopics ||
      savingCard
    );
  };

  return (
    <Dimmer active={active()} style={{ position: "fixed", zIndex: 10000000 }}>
      <Loader size="big">Loading</Loader>
    </Dimmer>
  );
};

export default Loading;
