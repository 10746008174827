// Base url
import { distanceMatrixApiKey } from "./secret";

const applicationApiBaseUrl = `${process.env.REACT_APP_APPLICATION_API_BASE_URL}/v1`;

// Auth urls
export const loginUrl = `${applicationApiBaseUrl}/auth/email`;
export const registerUrl = `${applicationApiBaseUrl}/auth/register`;
export const facebookUrl = `${applicationApiBaseUrl}/auth/facebook`;
export const forgotPasswordUrl = `${applicationApiBaseUrl}/auth/forgotPassword`;

// Experience urls
export const getExperiencesUrl = `${applicationApiBaseUrl}/businesses/:business_id/experiences`;
export const searchExperiencesUrl = `${applicationApiBaseUrl}/businesses/:business_id/experiences/search`;
export const suggestExperiencesUrl = `${applicationApiBaseUrl}/businesses/:business_id/experiences/search/suggestions`;
export const postExperienceUrl = `${applicationApiBaseUrl}/businesses/:business_id/experiences`;
export const putExperienceUrl = `${applicationApiBaseUrl}/businesses/:business_id/experiences/:experience_id`;
export const getExperienceUrl = `${applicationApiBaseUrl}/businesses/:business_id/experiences/:experience_id`;
export const deleteExperienceUrl = `${applicationApiBaseUrl}/businesses/:business_id/experiences/:experience_id`;

// Profile urls
export const getProfileUrl = `${applicationApiBaseUrl}/account/profile`;
export const putProfileUrl = `${applicationApiBaseUrl}/account/profile`;

// Credit cards urls
export const getCreditCardsUrl = `${applicationApiBaseUrl}/businesses/credit_cards`;
export const postCreditCardUrl = `${applicationApiBaseUrl}/businesses/credit_cards`;
export const putCreditCardUrl = `${applicationApiBaseUrl}/businesses/credit_cards/:id/set_default`;
export const deleteCreditCardUrl = `${applicationApiBaseUrl}/businesses/credit_cards/:id`;

// Distance matrix
const distanceMatrixApiBaseUrl = `${process.env.REACT_APP_DISTANCE_MATRIX_API_BASE_URL}/v1`;
export const postDistanceMatrixUrl = `${distanceMatrixApiBaseUrl}/distancematrix?key=${distanceMatrixApiKey}`;

// Topics
export const getTopicsUrl = `${applicationApiBaseUrl}/topics`;

// Destinations
export const getDestinationsUrl = `${applicationApiBaseUrl}/destinations`;

// Privacy Policy
export const getPrivacyPolicyUrl = `https://www.onetwotrail.com/privacy-policy`;
