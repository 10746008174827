import React from "react";
import { Button, Card } from "semantic-ui-react";
import { ReactComponent as DeleteIcon } from "../../../../../assets/Icons/close-experience.svg";

export const ExperienceFormImageCard = ({ imageIndex, imageUrl, primary, onImageDelete, onImagePrimaryToggle }) => {
  return (
    <>
      <Card
        className={"experience-form-image-card"}
        style={{
          height: "96px",
          width: "96px",
          background: "transparent",
          boxShadow: "none",
          marginLeft: "4px",
          marginRight: "4px",
        }}
      >
        <div
          style={{
            height: "100%",
            position: "relative",
            display: "inline-block",
            textAlign: "center",
            borderRadius: "20px",
          }}
          className="image"
        >
          <img
            src={imageUrl}
            alt=""
            style={{
              height: "100%",
              objectFit: "cover",
              backgroundPosition: "center",
            }}
          />
          <Button
            circular
            icon="star outline"
            color={primary ? "teal" : "grey"}
            onClick={onImagePrimaryToggle.bind(this, imageIndex)}
            size="mini"
            style={{
              position: "absolute",
              top: "-4px",
              right: "24px",
              zIndex: "999",
            }}
          />
          <i
            aria-hidden="true"
            style={{
              height: "28px",
              width: "28px",
              display: "flex",
              position: "absolute",
              alignItems: "center",
              top: "-4px",
              right: "-4px",
              cursor: "pointer",
            }}
            className="right"
            onClick={onImageDelete.bind(this, imageIndex)}
          >
            <DeleteIcon />
          </i>
        </div>
      </Card>
    </>
  );
};
