import { Card, Icon } from "semantic-ui-react";
import React from "react";
import { ReactComponent as DeleteIcon } from "../../../../../assets/Icons/close-experience.svg";

export const ExperienceFormProductCard = ({ productIndex, product, onProductDelete }) => {
  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>{product.name}</Card.Header>
        <Card.Meta>{product.duration} hour(s)</Card.Meta>
        <Card.Description>{product.description}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <Icon name="dollar" />
        {product.price}
      </Card.Content>
      <i
        aria-hidden="true"
        style={{
          height: "30px",
          width: "30px",
          display: "flex",
          position: "absolute",
          alignItems: "center",
          top: "-4px",
          right: "-4px",
          cursor: "pointer",
        }}
        className="right"
        onClick={onProductDelete.bind(this, productIndex)}
      >
        <DeleteIcon />
      </i>
    </Card>
  );
};
