import React, { useEffect, useState } from "react";
import { Grid } from "semantic-ui-react";
import NavBar from "../NavBar";
import { useSelector } from "react-redux";

const AppBase = ({ children, style }) => {
  // Whether the main page is clicked or not.
  const [mainClicked, setMainClicked] = useState(false);

  // An effect to scroll to the top of the page when the page is loaded.
  useEffect(() => {
    window.parent.document.body.style.zoom = 0;
  }, []);

  // Whether experiences is loading or not.
  const experiencesLoading = useSelector((state) => state.experiences.loading);

  // An effect to reduce the brightness if a resource is loading.
  useEffect(() => {
    if (experiencesLoading) {
      document.body.style.filter = "brightness(.6)";
    } else {
      document.body.style.filter = "brightness(1)";
    }
  }, [experiencesLoading]);

  return (
    <>
      <Grid
        style={{
          backgroundColor: "#eee4cf",
          height: "100vh",
          margin: 0,
        }}
      >
        <Grid.Row
          style={{
            padding: 0,
          }}
        >
          <NavBar mainClicked={mainClicked} mainClickedCallback={setMainClicked.bind(this, false)} />
        </Grid.Row>
        <Grid.Row
          style={{
            padding: 0,
            height: "calc(100vh - 80px)",
          }}
        >
          <Grid.Column onClick={() => !mainClicked && setMainClicked(true)} style={{ padding: 0 }}>
            {children}
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </>
  );
};

export default AppBase;
