import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Image, Menu } from "semantic-ui-react";
import greenLogo from "../../assets/Logos/green-logo.png";
import pinkLogo from "../../assets/Logos/pink-logo.png";
import navbarImage from "../../assets/Images/background-page.png";
import * as ROUTES from "../../constants/routes";
import "./NavBar.css";
import { useSelector } from "react-redux";
import { ReactComponent as ProfileIcon } from "../../assets/Icons/profile.svg";

const NavBar = ({ mainClicked, mainClickedCallback, activeRegister, landing = false }) => {
  const [display, setDisplay] = useState(false);
  const [load, setLoad] = useState(false);

  const isLogged = useSelector((store) => store.authentication.isLogged);

  const backgroundImage = () => {
    return isLogged && !landing
      ? {
          backgroundImage: `url(${navbarImage})`,
        }
      : {
          backgroundImage: "none",
        };
  };

  useEffect(() => {
    if (mainClicked) {
      display && setDisplay(false);
      mainClickedCallback(false);
    }
  }, [mainClicked, display, mainClickedCallback]);

  useEffect(() => {
    if (!load) {
      setLoad(true);
    }
  }, [load]);

  return (
    <>
      <Menu
        size="large"
        className="menu-container"
        style={{
          ...backgroundImage(),
          height: "80px",
          width: "100%",
        }}
      >
        <Menu.Item header className="menu-container-logo">
          <Link to={isLogged ? ROUTES.CONSOLE : ROUTES.ROOT}>
            <Image src={activeRegister ? pinkLogo : greenLogo} className="white-logo" />
          </Link>
        </Menu.Item>
        {isLogged && (
          <Menu.Item position="right" className="menu-container-right">
            <Button
              icon
              labelPosition="right"
              type="button"
              floated="right"
              color="pink"
              circular
              style={{
                width: "200px",
                height: "44px",
                backgroundColor: "#e78db3",
                fontSize: "16px",
                fontWeight: "500",
                color: "#ffffff",
                boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
              }}
              as={Link}
              to={ROUTES.PROFILE}
            >
              My Profile
              <i
                aria-hidden="true"
                className="icon right"
                style={{
                  backgroundColor: "transparent",
                  right: "2em",
                }}
              >
                <ProfileIcon />
              </i>
            </Button>
          </Menu.Item>
        )}
      </Menu>
    </>
  );
};

export default NavBar;
