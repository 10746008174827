import React, { useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import NavBar from "../../../components/NavBar";
import LoginInfo from "./LoginInfo";
import "./LoginBase.css";

const LoginBase = ({ children, style }) => {
  const [mainClicked, setMainClicked] = useState(false);

  return (
    <Container className="container-background" style={style}>
      <Container className="background-container-login">
        <NavBar mainClicked={mainClicked} mainClickedCallback={setMainClicked.bind(this, false)} />
        <Grid columns={2} className="login-container" onClick={() => !mainClicked && setMainClicked(true)}>
          <Grid.Column mobile={16} tablet={16} computer={7}>
            <LoginInfo />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={7}>
            {children}
          </Grid.Column>
        </Grid>
      </Container>
    </Container>
  );
};

export default LoginBase;
