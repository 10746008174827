import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, withRouter } from "react-router-dom";
import { Grid } from "semantic-ui-react";
import AppBase from "../../../../components/AppBase";
import { clearExperience, createLocalExperience, getExperience } from "../../../../redux/experienceDucks";
import { ExperienceFormFields } from "./ExperienceFormFields/ExperienceFormFields";
import { ExperienceFormPreview } from "./ExperienceFormPreview/ExperienceFormPreview";

export const ExperienceForm = (props) => {
  // A selector to load the experience from the store.
  const experience = useSelector((store) => store.experiences.experience);

  // A hook to dispatch state changes.
  const dispatch = useDispatch();

  // A hook to access the history object from the react-router-dom package.
  const history = useHistory();

  // If there's no experience, create a new one.
  useEffect(() => {
    // If the route has an ID, load the experience from the server.
    let experienceId = props.match.params.id;
    if (experienceId) {
      dispatch(getExperience(experienceId));
    } else {
      // Otherwise, create a new experience.
      dispatch(createLocalExperience());
    }
    return () => {
      // When the component is unmounted, clear the experience from the store.
      dispatch(clearExperience());
    };
  }, [props.match.params.id]);

  // An effect to redirect to the edit page of the experience when the experience is created.
  useEffect(() => {
    if (!experience) {
      return;
    }
    if (experience.value.id.hasValue() && !props.match.params.id) {
      history.push(`/experiences/${experience.value.id.value}/edit`);
    }
  });

  return (
    <>
      <AppBase>
        {experience && (
          <Grid
            style={{
              margin: 0,
            }}
          >
            <Grid.Column
              mobile={16}
              tablet={4}
              computer={4}
              style={{
                overflowY: "auto",
                height: "calc(100vh - 80px)",
                padding: 0,
              }}
            >
              <ExperienceFormFields />
            </Grid.Column>
            <Grid.Column
              mobile={16}
              tablet={12}
              computer={12}
              style={{
                overflowY: "auto",
                height: "calc(100vh - 80px)",
                padding: 0,
              }}
            >
              <ExperienceFormPreview />
            </Grid.Column>
          </Grid>
        )}
      </AppBase>
    </>
  );
};

export default withRouter(ExperienceForm);
