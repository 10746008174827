export const equalTo = (valueA, valueB) => {
  if (typeof valueA === "object") {
    for (const key in valueA) {
      if (!equalTo(valueA[key], valueB[key])) {
        return false;
      }
    }
  } else if (Array.isArray(valueA)) {
    if (valueA.length !== valueB.length) {
      return false;
    }
    for (let i = 0; i < valueA.length; i++) {
      if (!equalTo(valueA[i], valueB[i])) {
        return false;
      }
    }
  } else {
    return valueA === valueB;
  }
  return true;
};
