import { facebookUrl, forgotPasswordUrl, loginUrl, registerUrl } from "../constants/url";
import { resetStore } from "./store";
import { LOCAL_STORAGE_KEYS } from "../constants/localStorage";
import { patchAxios, postAxios, putAxios } from "../services/Axios";
import { handleError } from "./appDucks";

const INITIAL_STATE = {
  activeStep: 0,
  error: null,
  forgetPasswordEmailSent: false,
  isLogged: false,
  loading: false,
  resetPassword: false,
  user: null,
};

const USER_SIGN_IN_SUCCESS = "USER_SIGN_IN_SUCCESS";
const USER_SIGN_IN_ERROR = "USER_SIGN_IN_ERROR";
const USER_SIGN_UP_ERROR = "USER_SIGN_UP_ERROR";
const USER_SEND_RESET_PASSWORD_EMAIL_SUCCESS = "USER_SEND_RESET_PASSWORD_EMAIL_SUCCESS";
const USER_RESET_PASSWORD_SUCCESS = "USER_RESET_PASSWORD_SUCCESS";
const USER_RESET_PASSWORD_ERROR = "USER_RESET_PASSWORD_ERROR";
const USER_INCREMENT_ACTIVE_STEP = "USER_INCREMENT_ACTIVE_STEP";
const USER_RESET_ACTIVE_STEP = "USER_RESET_ACTIVE_STEP";
const USER_START_LOADING = "USER_START_LOADING";
const USER_STOP_LOADING = "USER_STOP_LOADING";

export default function reducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case USER_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: action.payload,
        error: null,
        isLogged: true,
      };
    case USER_SIGN_IN_ERROR:
      return { ...state, error: action.payload };
    case USER_SIGN_UP_ERROR:
      return { ...state, error: action.payload, activeStep: 0 };
    case USER_SEND_RESET_PASSWORD_EMAIL_SUCCESS:
      return { ...state, forgetPasswordEmailSent: true, error: null };
    case USER_RESET_PASSWORD_SUCCESS:
      return { ...state, resetPassword: true, error: null };
    case USER_RESET_PASSWORD_ERROR:
      return { ...state, resetPasswordError: true };
    case USER_INCREMENT_ACTIVE_STEP:
      return { ...state, activeStep: state.activeStep + 1 };
    case USER_RESET_ACTIVE_STEP:
      return { ...INITIAL_STATE };
    case USER_START_LOADING:
      return { ...state, loading: true };
    case USER_STOP_LOADING:
      return { ...state, loading: false };
    default:
      return state;
  }
}

function fromSessionMessageToUser(message) {
  // If business_id not present, throw error
  if (!message.business_id) {
    throw new Error("business_id not present in session message");
  }
  // If token not present, throw error
  if (!message.token) {
    throw new Error("token not present in session message");
  }
  // If is_admin not present, throw error
  if (message.is_admin === undefined) {
    throw new Error("is_admin not present in session message");
  }
  return {
    token: message.token,
    businessId: message.business_id,
    isAdmin: message.is_admin,
  };
}

const handleSessionResponse = (response, dispatch) => {
  if (response.status !== 200) {
    dispatch(handleError("Unexpected session response"));
    return;
  }
  const user = fromSessionMessageToUser(response.data);
  dispatch({
    type: USER_SIGN_IN_SUCCESS,
    payload: user,
  });
  const json = JSON.stringify(user);
  return localStorage.setItem(LOCAL_STORAGE_KEYS.user, json);
};

export const signIn = (user) => async (dispatch) => {
  dispatch({
    type: USER_START_LOADING,
  });
  try {
    const response = await postAxios(loginUrl, user);
    handleSessionResponse(response, dispatch);
  } catch (error) {
    if (error.response && error.response.status === 401) {
      dispatch({
        type: USER_SIGN_IN_ERROR,
        payload: "Please verify your email address",
      });
      return;
    }
    dispatch(handleError(error));
  } finally {
    dispatch({
      type: USER_STOP_LOADING,
    });
  }
};

export const signUpWithEmail = (user) => async (dispatch) => {
  dispatch({
    type: USER_START_LOADING,
  });
  let request = {
    first_name: user.firstName,
    last_name: user.lastName,
    country: "CR",
    email: user.email,
    phone_number: user.phoneNumber,
    password: user.password,
  };
  try {
    await postAxios(registerUrl, request);
  } catch (error) {
    if (error.response && error.response.status === 422) {
      dispatch({
        type: USER_SIGN_UP_ERROR,
        payload: !!error.response.data.errors.email && "Address already in use",
      });
      return;
    }
    dispatch(handleError(error));
  } finally {
    dispatch({
      type: USER_STOP_LOADING,
    });
  }
};

export const signUpWithFacebook = (token) => async (dispatch) => {
  dispatch({
    type: USER_START_LOADING,
  });
  try {
    const response = await postAxios(facebookUrl, { access_token: token });
    handleSessionResponse(response, dispatch);
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({
      type: USER_STOP_LOADING,
    });
  }
};

export const sendResetPasswordEmail = (email) => async (dispatch) => {
  dispatch({
    type: USER_START_LOADING,
  });
  try {
    const response = await patchAxios(forgotPasswordUrl, { email: email });
    if (response.status !== 202) {
      dispatch(handleError("Unexpected forgot password response"));
      return;
    }
    dispatch({
      type: USER_SEND_RESET_PASSWORD_EMAIL_SUCCESS,
    });
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({
      type: USER_STOP_LOADING,
    });
  }
};

export const resetPassword = (password, confirmationPassword, token) => async (dispatch) => {
  dispatch({
    type: USER_START_LOADING,
  });
  let request = {
    reset_password_token: token,
    password: password,
    password_confirmation: confirmationPassword,
  };
  try {
    const response = await putAxios(forgotPasswordUrl, request);
    if (response.status <= 204) {
      return dispatch({
        type: USER_RESET_PASSWORD_SUCCESS,
      });
    }
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch({
      type: USER_STOP_LOADING,
    });
  }
};

export const signOut = () => (dispatch) => {
  localStorage.clear();
  dispatch(resetStore());
};

export const resetActiveStep = () => (dispatch) => {
  dispatch({ type: USER_RESET_ACTIVE_STEP });
};

export const incrementActiveStep = () => (dispatch) => {
  dispatch({ type: USER_INCREMENT_ACTIVE_STEP });
};

export const getUserFromLocalStorage = () => (dispatch) => {
  let json = localStorage.getItem(LOCAL_STORAGE_KEYS.user);
  if (json) {
    dispatch({
      type: USER_SIGN_IN_SUCCESS,
      payload: JSON.parse(json),
    });
  }
};

export const isAdminUser = (state) => {
  return state.authentication.user && state.authentication.user.isAdmin;
};
