import React, { useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import NavBar from "../../../components/NavBar";
import RegisterInfo from "./RegisterInfo";
import BackgroundImage from "../../../assets/Images/background-register.png";

const RegisterBase = ({ children }) => {
  const [mainClicked, setMainClicked] = useState(false);

  return (
    <Container
      style={{
        width: "100vw",
        height: "100%",
        backgroundColor: "#464646",
      }}
    >
      <Container
        style={{
          height: "100%",
          minHeight: "100vh",
          width: "100vw",
          maxWidth: "100vw",
          backgroundImage: `url(${BackgroundImage})`,
          backgroundPosition: "top",
          backgroundRepeat: "no-repeat",
          backgroundSize: "cover",
          position: "relative",
        }}
      >
        <NavBar
          activeRegister={true}
          mainClicked={mainClicked}
          mainClickedCallback={setMainClicked.bind(this, false)}
        />
        <Grid
          columns={2}
          className="register-container"
          onClick={() => !mainClicked && setMainClicked(true)}
          style={{
            cursor: mainClicked ? "default" : "pointer",
            height: "calc(100vh - 80px)",
            margin: "0",
            display: "flex",
            justifyContent: "space-around",
            alignItems: "center",
            padding: "0 8vh",
          }}
        >
          <Grid.Column computer={7}>
            <RegisterInfo />
          </Grid.Column>
          <Grid.Column computer={7}>{children}</Grid.Column>
        </Grid>
      </Container>
    </Container>
  );
};

export default RegisterBase;
