import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withRouter } from "react-router-dom";
import { resetActiveStep, signIn } from "../../redux/userDucks";
import * as ROUTES from "../../constants/routes";
import { Segment } from "semantic-ui-react";
import BlockAccount from "./BlockAccount";
import FormDataLogin from "./FormLogin";
import LoginBase from "./LoginBase";
import "./Login.css";
import { displayMessage } from "../../redux/appDucks";

const Login = (props) => {
  const isLogged = useSelector((store) => store.authentication.isLogged);
  const error = useSelector((store) => store.authentication.error);
  const counterBlockAccount = useSelector((store) => store.authentication.counterBlockAccount);
  const [openBlockAccount, setOpenBlockAccount] = useState(false);
  const [userError, setUserError] = useState({});
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const dispatch = useDispatch();

  const validateUserFields = (user) => {
    const error = {};
    if (user.email === null || user.email.length === 0 || /^\s+$/.test(user.email)) {
      error.email = "Please enter an email";
    } else if (!validateUserEmail(user.email)) {
      error.email = "Please enter a valid email";
    }
    if (user.password === null || user.password.length === 0 || /^\s+$/.test(user.password)) {
      error.password = "Please enter a password";
    }
    return error;
  };

  const validateUserEmail = (email) => {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    const userError = validateUserFields(user);
    setUserError(userError);
    if (Object.entries(userError).length === 0) dispatch(signIn(user));
  };

  const onChange = (event) => {
    const { name, value } = event.target;
    setUser({ ...user, [name]: value });
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(props.location.search);
    // if query params empty, return
    if (queryParams.toString().length === 0) {
      return;
    }
    const confirmation = queryParams.get("c");
    if (confirmation === "1") {
      dispatch(
        displayMessage({
          header: "Account confirmed",
          body: "Your account has been confirmed.",
          type: "info",
        }),
      );
      return;
    }
    const registration = queryParams.get("r");
    if (registration === "1") {
      dispatch(
        displayMessage({
          header: "Registration completed",
          body: "Your account has been created. Please check your email to confirm your account.",
          type: "info",
        }),
      );
    }
  });

  useEffect(() => {
    isLogged ? props.history.push(ROUTES.CONSOLE) : props.history.push(ROUTES.LOGIN);
  }, [isLogged, props.history]);

  useEffect(() => {
    setUserError({ password: error });
  }, [error]);

  useEffect(() => {
    if (!isLogged) dispatch(resetActiveStep());
  }, [dispatch, isLogged]);

  useEffect(() => {
    setOpenBlockAccount(counterBlockAccount >= 3);
  }, [counterBlockAccount]);

  return (
    <>
      {openBlockAccount && (
        <BlockAccount open={openBlockAccount} handleCloseMessage={setOpenBlockAccount.bind(this, false)} />
      )}
      <LoginBase style={{ filter: `${openBlockAccount ? "brightness(.6)" : "brightness(1)"}` }}>
        <Segment className="login-data-container">
          <FormDataLogin user={user} handleOnChange={onChange} handleOnSubmit={onSubmit} handleUserError={userError} />
        </Segment>
      </LoginBase>
    </>
  );
};

export default withRouter(Login);
