import React from "react";
import { useSelector } from "react-redux";
import { Button, Grid, Header, Image } from "semantic-ui-react";
import { ReactComponent as AddExperienceIcon } from "../../../assets/Icons/add-experience.svg";
import defaultImage from "../../../assets/Images/defaultImage.jpg";
import "./UserProfileImage.css";

const UserProfileImage = ({ setOpenImage, userProfile }) => {
  const storeUserProfile = useSelector((store) => store.profile.profile);

  return (
    <Grid centered columns={2}>
      <Grid.Column computer={2} className="profile-image-container">
        <Image
          src={userProfile.avatar ? userProfile.avatar : defaultImage}
          className="profile-image"
          circular
          as="label"
          onClick={() => setOpenImage(true)}
        />
        <Button icon as="label" type="button" className="profile-image-add-button" onClick={() => setOpenImage(true)}>
          <i aria-hidden="true" className="icon right profile-image-add-button-icon">
            <AddExperienceIcon />
          </i>
        </Button>
      </Grid.Column>
      <Grid.Column computer={10} style={{ paddingLeft: "40px" }}>
        <Header className="profile-name">Hello {storeUserProfile.firstName}!</Header>
        <Header className="profile-edit-text">Edit your profile</Header>
      </Grid.Column>
    </Grid>
  );
};

export default UserProfileImage;
