import React from "react";
import { withRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import FacebookLogin from "react-facebook-login";
import { signUpWithFacebook } from "../../redux/userDucks";
import "./FacebookButton.css";
import { facebookAppId } from "../../constants/secret";
import { Icon } from "semantic-ui-react";

const FacebookButton = (props) => {
  const dispatch = useDispatch();

  const responseFacebook = (data) => {
    const token = data.accessToken;
    if (!!token) dispatch(signUpWithFacebook(token));
  };

  return (
    <FacebookLogin
      cookie={true}
      appId={facebookAppId}
      autoLoad={false}
      fields="name,email,picture"
      callback={responseFacebook}
      icon={<Icon name={"facebook"} size={"large"} />}
      textButton="Sign up with Facebook"
      cssClass="login-button-register-fb"
      disableMobileRedirect={true}
    />
  );
};

export default withRouter(FacebookButton);
