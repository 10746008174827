import React from "react";
import Slider from "@material-ui/core/Slider";
import "./ExperienceFormDoubleRangeInput.css";

export const ExperienceFormDoubleRangeInput = ({ min, max, step, value, marks, track = "normal", name, onChange }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Slider
        name={name}
        track={track}
        min={min}
        max={max}
        step={step}
        value={value}
        marks={marks}
        onChange={onChange}
        style={{
          width: "calc(100% - 32px)",
          height: "5px",
          color: "#32bcad",
          fontSize: "12px",
          lineHeight: "1.5",
          margin: "12px 0 0 0",
          padding: "20px 0 0 0",
        }}
      />
    </div>
  );
};
