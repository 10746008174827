import React, { useState } from "react";
import { Container, Grid } from "semantic-ui-react";
import NavBar from "../../../components/NavBar";
import PasswordInfo from "./PasswordInfo";
import "./PasswordBase.css";

const PasswordBase = ({ children }) => {
  const [mainClicked, setMainClicked] = useState(false);

  return (
    <Container className="container-background-password">
      <Container className="background-container-password">
        <NavBar mainClicked={mainClicked} mainClickedCallback={setMainClicked.bind(this, false)} />
        <Grid columns={2} className="password-container" onClick={() => !mainClicked && setMainClicked(true)}>
          <Grid.Column mobile={16} tablet={16} computer={9} className="password-container-info">
            <PasswordInfo />
          </Grid.Column>
          <Grid.Column mobile={16} tablet={16} computer={7}>
            {children}
          </Grid.Column>
        </Grid>
      </Container>
    </Container>
  );
};

export default PasswordBase;
