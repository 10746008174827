import React from "react";
import { Grid, Header } from "semantic-ui-react";
import Logo from "../../../../assets/Logos/white-logo.png";
import "./PasswordInfo.css";

const PasswordInfo = () => {
  return (
    <Grid.Column>
      <Grid.Row>
        <Grid>
          <Grid.Column width={16}>
            <img src={Logo} alt="" />
          </Grid.Column>
          <Grid.Column width={16}>
            <Header className="password-info-title">Restore your password</Header>
          </Grid.Column>
        </Grid>
      </Grid.Row>
    </Grid.Column>
  );
};

export default PasswordInfo;
