import React from "react";
import "./FormInput.css";

const FormInput = ({ name, value, placeholder, type, IconInput, handleOnChange, error, style, disabled = false }) => {
  return (
    <div className="field form-input">
      <div className="ui big left icon input">
        {type === "password" ? (
          <input
            name={name}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={handleOnChange.bind(this)}
            style={style}
          />
        ) : (
          <input
            name={name}
            placeholder={placeholder}
            type={type}
            value={value}
            onChange={handleOnChange.bind(this)}
            style={style}
            disabled={disabled}
          />
        )}
        <i aria-hidden="true" className="icon">
          <IconInput />
        </i>
      </div>
      {error && <label className="form-input-error">{error}</label>}
    </div>
  );
};

export default FormInput;
