import React from "react";
import { Button, Checkbox, Form, Grid, Header, Icon } from "semantic-ui-react";
import Stepper from "../Stepper/Stepper";
import FormInput from "../../../../components/FormInput";
import { ReactComponent as LockEmptyIcon } from "../../../../assets/Icons/lock-empty.svg";
import { ReactComponent as LockCompleteIcon } from "../../../../assets/Icons/lock-complete.svg";
import { ReactComponent as CancelIcon } from "../../../../assets/Icons/cancel.svg";
import { ReactComponent as CheckIcon } from "../../../../assets/Icons/check.svg";
import "./FormPasswordUser.css";

const FormPasswordUser = ({ user, handleOnSubmit, handleOnChange, handleUserFieldsFormat, handleUserError }) => {
  return (
    <Form className="form-register-password" onSubmit={handleOnSubmit}>
      <Header className="title-form-register-password">Choose your password</Header>
      <FormInput
        name="password"
        value={user.password}
        placeholder="Password"
        type="password"
        IconInput={!user.password ? LockEmptyIcon : LockCompleteIcon}
        handleOnChange={handleOnChange.bind(this)}
      />
      <FormInput
        name="confirmPassword"
        value={user.confirmPassword}
        placeholder="Confirm password"
        type="password"
        IconInput={!user.confirmPassword ? LockEmptyIcon : LockCompleteIcon}
        handleOnChange={handleOnChange.bind(this)}
        error={handleUserError.passwordError}
      />
      <Grid.Row className="register-password-check-list">
        <Header className="register-password-check-item">
          <i aria-hidden="true" className="icon">
            {handleUserFieldsFormat.isPasswordLength ? <CheckIcon /> : <CancelIcon />}
          </i>
          Must be 8 characters
        </Header>
        <Header className="register-password-check-item">
          <i aria-hidden="true" className="icon">
            {handleUserFieldsFormat.isPasswordCapitalLetter ? <CheckIcon /> : <CancelIcon />}
          </i>
          Must include a capital letter
        </Header>
        <Header className="register-password-check-item">
          <i aria-hidden="true" className="icon">
            {handleUserFieldsFormat.isPasswordNumber ? <CheckIcon /> : <CancelIcon />}
          </i>
          Must include a number
        </Header>
      </Grid.Row>
      <Checkbox
        name="terms"
        label="I agree to the Terms and Conditions"
        className="register-password-privacy-policy"
        onChange={handleOnChange.bind(this)}
        checked={user.terms}
      />
      <Stepper />
      <Grid.Row className="register-password-container-button">
        <Button
          icon
          labelPosition="right"
          style={{
            width: "214px",
            height: "51px",
            borderRadius: "35px",
            fontSize: "16px",
            fontWeight: "500",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.13",
            letterSpacing: "normal",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "var(--principal-color-page)",
            color: "#FFFFFF",
          }}
        >
          Submit
          <Icon
            name="play"
            size="small"
            style={{
              backgroundColor: "transparent",
              right: "28px",
              paddingLeft: "unset",
              paddingRight: "0.9em",
              color: "#FFFFFF",
            }}
          />
        </Button>
      </Grid.Row>
    </Form>
  );
};

export default FormPasswordUser;
