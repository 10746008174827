import { Button, Card, Icon } from "semantic-ui-react";
import React from "react";

export const ExperienceFormProductAddButton = ({ addingProduct, onNewClick, onCancelClick }) => {
  let color, icon, onClick;
  if (addingProduct) {
    color = "red";
    icon = "cancel";
    onClick = onCancelClick;
  } else {
    color = "teal";
    icon = "plus";
    onClick = onNewClick;
  }
  return (
    <Card fluid style={{ backgroundColor: "rgba(132, 132, 132, 0.4)" }}>
      <Card.Content extra textAlign={"center"}>
        <Button icon onClick={onClick} color={color} type={"button"} circular>
          <Icon name={icon} />
        </Button>
      </Card.Content>
    </Card>
  );
};
