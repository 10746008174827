import { equalTo } from "./object";
import { FieldValidator } from "./validator";

export class Field {
  constructor(value, options = {}) {
    this.value = value;
    this.initialValue = options.initialValue ?? value;
    this.label = options.label;
    this.modified = options.modified ?? null;
    this.error = options.error ?? null;
    this.validator = options.validator ?? new FieldValidator();
  }

  duplicate() {
    return new this.constructor(this.value, {
      initialValue: this.initialValue ?? this.value,
      label: this.label,
      modified: this.modified,
      error: this.error,
      validator: this.validator,
    });
  }

  setModified(modified) {
    if (Array.isArray(this.value)) {
      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i] instanceof Field) {
          this.value[i].setModified(modified);
          continue;
        }
        this.modified = modified;
      }
    } else if (this.value instanceof Object) {
      for (const key in this.value) {
        if (this.value[key] instanceof Field) {
          this.value[key].setModified(modified);
          continue;
        }
        this.modified = modified;
      }
    } else {
      this.modified = modified;
    }
  }

  isModified() {
    if (this.modified === true) {
      return true;
    }
    if (this.modified === false) {
      return false;
    }
    if (Array.isArray(this.value)) {
      if (this.initialValue != null && this.value.length !== this.initialValue.length) {
        return true;
      }
      for (let i = 0; i < this.value.length; i++) {
        if (this.value[i] instanceof Field) {
          if (this.value[i].isModified()) {
            return true;
          }
          continue;
        }
        if (this.initialValue !== null && !equalTo(this.value[i], this.initialValue[i])) {
          return true;
        }
      }
    } else if (this.value instanceof Object) {
      for (const key in this.value) {
        if (this.value[key] instanceof Field) {
          if (this.value[key].isModified()) {
            console.debug("isModified", "key:", key);
            return true;
          }
          continue;
        }
        if (["_update"].includes(key)) {
          continue;
        }
        if (this.initialValue !== null && !equalTo(this.value[key], this.initialValue[key])) {
          return true;
        }
      }
    } else if (!equalTo(this.value, this.initialValue)) {
      return true;
    }
    return false;
  }

  hasValue() {
    if (this.value === undefined) {
      return false;
    }
    if (this.value === null) {
      return false;
    }
    if (typeof this.value === "object") {
      for (const key in this.value) {
        if (this.value[key] instanceof Field && this.value[key].required) {
          if (!this.value[key].hasValue()) {
            return false;
          }
        }
      }
    }
    return true;
  }

  async setValue(value, { overwriteValueOnLoad = false } = {}) {
    if (overwriteValueOnLoad) {
      this.initialValue = value;
    }
    this.value = value;
    if (this.validator) {
      await this.validator.validate(this);
    }
    return this;
  }

  setError(error) {
    this.error = error;
    return this;
  }

  hasError() {
    return this.error !== null && this.error !== undefined;
  }

  isValid() {
    // If validator is not set, assume it is valid
    if (!this.validator) {
      return true;
    }
    // Otherwise, run the validator
    return this.validator.isValid(this);
  }

  setValidator(validator) {
    this.validator = validator;
  }

  async validateDeep() {
    if (Array.isArray(this.value)) {
      for (let i = 0; i < this.value.length; i++) {
        if (!(this.value[i] instanceof Field)) {
          continue;
        }
        await this.value[i].validateDeep();
      }
    } else if (this.value instanceof Object) {
      for (const key in this.value) {
        if (!(this.value[key] instanceof Field)) {
          continue;
        }
        await this.value[key].validateDeep();
      }
    }
    if (this.validator) {
      await this.validator.validate(this);
    }
  }
}
