import { FieldValidator } from "../validator";

export class StringFieldValidator extends FieldValidator {}

export class NameFieldValidator extends StringFieldValidator {
  getError(field) {
    // If the length of the name is 0, return "Please enter a name"
    if (field.value.length === 0) {
      return "Please enter a name";
    }
    // If the length of the name is greater than 80 characters, return "Please enter a shorter name"
    if (field.value.length > 80) {
      return "Please enter a shorter name";
    }
    // Else, return null
    return null;
  }
}

export class EmailAddressFieldValidator extends StringFieldValidator {
  getError(field) {
    // If the value is not an email address, return "Please enter a valid email address"
    if (!field.value.match(/^[^@]+@[^@]+\.[^@]+$/)) {
      return "Please enter a valid email address";
    }
    // Else, return null
    return null;
  }
}

export class PhoneNumberFieldValidator extends StringFieldValidator {
  getError(field) {
    // If the value is not Costa Rican phone number, return "Please enter a Costa Rican phone number"
    if (!field.value.match(/^[2-8][0-9]{7}$/)) {
      return "Please enter a Costa Rican phone number";
    }
    // Else, return null
    return null;
  }
}

export class InternetUserNameFieldValidator extends StringFieldValidator {
  getError(field) {
    // If the value is not a name without spaces, return "Please enter alphanumeric separated by dots or underscores"
    if (!field.value.match(/^\w+(([._]\w+)|\w+)+$/)) {
      return "Please enter alphanumeric separated by dots or underscores";
    }
    // Else, return null
    return null;
  }
}

export class WebAddressFieldValidator extends StringFieldValidator {
  constructor(options) {
    super(options);
    // If options.hostname is not a valid hostname
    if (options.hostname && !this.isValidHostname(options.hostname)) {
      // Throw error "Invalid hostname"
      throw new Error("Invalid hostname: " + options.hostname);
    }
    // Set hostname to options.hostname
    this.hostname = options.hostname;
  }

  getError(field) {
    // If the value is not a valid web address, return "Please enter a valid web address"
    let webAddress = "^(http(s)?:\/\/)?";
    webAddress += this.hostname ? `(www\.)?(${this.hostname})` : "";
    webAddress += `([\/\\w .-]*)*\/?$`;
    const isWebAddress = field.value.match(new RegExp(webAddress));
    if (!isWebAddress && !this.hostname) {
      return "Please enter a valid web address";
    }
    if (!isWebAddress && this.hostname) {
      return `Please enter a valid web address from ${this.hostname}`;
    }
    // Else, return null
    return null;
  }

  isValidHostname(name) {
    // Whether name matches <word>.<word>
    return name.match(/^\w+\.\w+$/);
  }
}

export class DescriptionFieldValidator extends StringFieldValidator {
  getError(field) {
    // If the value contains less than 50 words, return "Please enter more than 50 words"
    if (field.value.split(" ").length < 50) {
      return "Please enter more than 50 words";
    }
    // If the value contains more than 200 words, return "Please enter less than 200 words"
    if (field.value.split(" ").length > 200) {
      return "Please enter less than 200 words";
    }
    // Else, return null
    return null;
  }
}
