import { faker } from "@faker-js/faker";
import { ExperienceField } from "../field";
import { ExperienceState } from "../state";

const mockTimetables = (count) => {
  let timetables = [];
  const weekdays = faker.helpers.uniqueArray([0, 1, 2, 3, 4, 5, 6], count);
  for (let i = 0; i < count; i++) {
    timetables.push({
      wday: weekdays[i],
      open_hour: `0${faker.number.int({ min: 5, max: 9 })}:00`,
      close_hour: `${faker.number.int({ min: 17, max: 23 })}:00`,
    });
  }
  return timetables;
};

const mockImages = (count) => {
  let images = [];
  for (let i = 0; i < count; i++) {
    images.push({
      pictureUrl: faker.image.urlLoremFlickr(),
    });
  }
  return images;
};

const mockProducts = (count) => {
  let products = [];
  for (let i = 0; i < count; i++) {
    products.push({
      name: faker.commerce.productName(),
      duration: faker.number.int({ min: 1, max: 4 }),
      price: faker.commerce.price(),
      description: faker.commerce.productDescription(),
    });
  }
  return products;
};

const mockTopics = (count) => {
  const DEFAULT_TOPICS = ["Art", "Nature", "Culture"];
  return faker.helpers.uniqueArray(DEFAULT_TOPICS, count);
};

export const mockExperienceField = (isAdmin) => {
  const experience = new ExperienceField();

  experience.value.contact.value.name.value = faker.person.firstName();
  experience.value.contact.value.phoneNumber.value = faker.phone.number("8#######");
  experience.value.contact.value.email.value = faker.internet.email();
  experience.value.contact.value.websiteUrl.value = faker.internet.url();
  experience.value.contact.value.facebookUrl.value = `https://www.facebook.com/${faker.internet.userName()}`;
  experience.value.contact.value.instagramUrl.value = `https://www.instagram.com/${faker.internet.userName()}`;
  experience.value.contact.value.whatsApp.value = faker.phone.number("8#######");

  experience.value.paymentMethods.value.sinpeMovil.value = faker.datatype.boolean();
  experience.value.paymentMethods.value.masterCard.value = faker.datatype.boolean();
  experience.value.paymentMethods.value.americanExpress.value = faker.datatype.boolean();
  experience.value.paymentMethods.value.visa.value = faker.datatype.boolean();
  experience.value.paymentMethods.value.bitcoin.value = faker.datatype.boolean();
  experience.value.paymentMethods.value.payPal.value = faker.datatype.boolean();
  experience.value.paymentMethods.value.bankTransfer.value = faker.datatype.boolean();
  experience.value.paymentMethods.value.cash.value = faker.datatype.boolean();

  experience.value.features.value.accommodation.value = faker.datatype.boolean();
  experience.value.features.value.adultsOnly.value = faker.datatype.boolean();
  experience.value.features.value.allTerrainVehicleOnly.value = faker.datatype.boolean();
  experience.value.features.value.birdWatching.value = faker.datatype.boolean();
  experience.value.features.value.camping.value = faker.datatype.boolean();
  experience.value.features.value.carbonNeutral.value = faker.datatype.boolean();
  experience.value.features.value.evCharger.value = faker.datatype.boolean();
  experience.value.features.value.foodDrink.value = faker.datatype.boolean();
  experience.value.features.value.internet.value = faker.datatype.boolean();
  experience.value.features.value.parking.value = faker.datatype.boolean();
  experience.value.features.value.petFriendly.value = faker.datatype.boolean();
  experience.value.features.value.publicTransport.value = faker.datatype.boolean();
  experience.value.features.value.securityLockers.value = faker.datatype.boolean();
  experience.value.features.value.showers.value = faker.datatype.boolean();
  experience.value.features.value.smokingArea.value = faker.datatype.boolean();
  experience.value.features.value.toilets.value = faker.datatype.boolean();
  experience.value.features.value.wheelchairAccessible.value = faker.datatype.boolean();

  experience.value.schedule.value.timetables.value = mockTimetables(3);
  experience.value.schedule.value.stayMinutes.value = faker.helpers.uniqueArray([30, 60, 90, 120], 1)[0];

  const locations = [
    [9.640620029467431, -83.93532139832998],
    [9.938174797929848, -84.10942292662197],
    [9.909525795095812, -84.01844152360597],
  ];
  const location = faker.helpers.arrayElement(locations);
  experience.value.location.value.latitude.value = location[0];
  experience.value.location.value.longitude.value = location[1];

  experience.value.images.value = mockImages(3);

  experience.value.products.value = mockProducts(2);

  experience.value.status.value.state.value = ExperienceState.DRAFT;

  experience.value.contact.setModified(true);
  experience.value.paymentMethods.setModified(true);
  experience.value.features.setModified(true);
  experience.value.schedule.setModified(true);
  experience.value.location.setModified(true);
  experience.value.images.setModified(true);
  experience.value.products.setModified(true);
  experience.value.status.setModified(true);

  if (isAdmin) {
    experience.value.description.value = faker.lorem.paragraph(10);
    experience.value.topics.value = mockTopics(3);
    experience.value.destination.value.id.value = faker.number.int({
      min: 1,
      max: 10,
    });

    experience.value.description.setModified(true);
    experience.value.topics.setModified(true);
    experience.value.destination.setModified(true);
  }

  return experience;
};
