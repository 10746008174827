import React, { useEffect, useRef, useState } from "react";
import { Message, Search } from "semantic-ui-react";
import "./ExperienceFormLocation.css";
import GoogleMap from "../../../../../components/Map/GoogleMap";
import { useDispatch, useSelector } from "react-redux";
import { updateLocalExperienceValue } from "../../../../../redux/experienceDucks";

export const ExperienceFormLocation = ({ height }) => {
  // A const for the dispatch function
  const dispatch = useDispatch();

  // A variable for retrieving the location of the experience from the store
  let location = useSelector((store) => store.experiences.experience.value.location);

  // A reference to the initialized Google Maps client
  const googleMapsRef = useRef(null);

  // A reference to the map instance
  const mapRef = useRef(null);

  // A reference to the Google Maps autocomplete service
  const autocompleteServiceRef = useRef(null);

  // A reference to the Google Maps geocoder
  const geocoderRef = useRef(null);

  // A state variable that holds the decoded address
  const [query, setQuery] = useState("");

  // A state variable for the search results
  const [results, setResults] = useState([]);

  // A state variable to store the predictions obtained from Google Maps
  const [predictions, setPredictions] = useState([]);

  // A reference to the div that contains the map.
  const mapDivRef = useRef(null);

  // A state variable for the height of the map.
  const [mapHeight, setMapHeight] = useState(0);

  // A state variable for the marker position
  const [markerPosition, setMarkerPosition] = useState({
    lat: location.value.latitude.value,
    lng: location.value.longitude.value,
  });

  // A callback that takes a mapProp and a map as arguments
  const handleMapReady = (google, map) => {
    // Set the googleMapsRef to the mapProps
    googleMapsRef.current = google.maps;
    // Set the mapRef to the map instance
    mapRef.current = map;
    // Get the google.maps.places.AutocompleteService from the mapProps
    autocompleteServiceRef.current = new google.maps.places.AutocompleteService();
    // Get the google.maps.Geocoder from the mapProps
    geocoderRef.current = new google.maps.Geocoder();
  };

  // A callback that takes the end LatLng of the marker drag event
  const handleDragEnd = (latLng) => {
    // Sets the address state variable to the decoded address
    geocoderRef.current.geocode({ location: latLng }, async (results, status) => {
      // If the status is OK, set the address state variable to the formatted address
      if (status === "OK") {
        // Clear the query.
        setQuery("");
        // Move the map camera to the new location
        mapRef.current.panTo(latLng);
        // Update the location of the experience
        location = await location.setValue({
          latitude: await location.value.latitude.setValue(latLng.lat()),
          longitude: await location.value.longitude.setValue(latLng.lng()),
        });
        dispatch(updateLocalExperienceValue({ location }));
      } else {
        console.error(`Geocoder failed due to: ${status}`);
      }
    });
  };

  const handleResultSelect = (event, data) => {
    // Search for the prediction that matches the selected result.
    const prediction = predictions.find((prediction) => {
      return prediction.description === data.result.title;
    });
    // If no prediction is found, return.
    if (!prediction) {
      console.error("No prediction found for the selected result.");
      return;
    }
    // Retrieve the coordinates of the selected result using the place ID.
    geocoderRef.current.geocode({ placeId: prediction.placeId }, async (results, status) => {
      // If the status is OK, set the map center to the coordinates of the selected result.
      if (status === "OK" && results[0]) {
        mapRef.current.panTo(results[0].geometry.location);
        // Join the main text and secondary text of the prediction.
        // Set the search query to the title of the result.
        setQuery(`${prediction.description}`);
        // Update the value of the location
        location = await location.setValue({
          latitude: await location.value.latitude.setValue(results[0].geometry.location.lat()),
          longitude: await location.value.longitude.setValue(results[0].geometry.location.lng()),
        });
        // Update the position of the marker
        setMarkerPosition({
          lat: location.value.latitude.value,
          lng: location.value.longitude.value,
        });
        // Update the location of the experience
        dispatch(updateLocalExperienceValue({ location }));
      } else {
        console.error(`Geocoder failed due to: ${status}`);
      }
    });
    // Clear the search results.
    setResults([]);
  };

  function searchGoogleMapsByName(query) {
    if (!autocompleteServiceRef.current || !googleMapsRef.current) {
      setResults([]);
      return;
    }
    // Create an autocompletion request.
    // Sets the query as the input.
    // Limits the search to addresses in Costa Rica.
    // Includes geometry information in the result.
    const request = {
      input: query,
      componentRestrictions: { country: "cr" },
      fields: ["address"],
    };
    // Retrieve place predictions from the autocomplete service.
    autocompleteServiceRef.current.getPlacePredictions(request, (predictions, status) => {
      if (status !== googleMapsRef.current.places.PlacesServiceStatus.OK || !predictions) {
        return;
      }
      predictions = predictions.map((result) => {
        return {
          // Store the main text and secondary text in the result.
          description: result.description,
          placeId: result.place_id, // Store the description in the result.
        };
      });
      setPredictions(predictions);
      // Map the predictions to the format required by the Search component.
      let results = predictions.map((result) => {
        return {
          title: result.description,
        };
      });
      setResults(results);
    });
  }

  const handleSearchChange = (event) => {
    const value = event.target.value;
    setQuery(value);
    if (value.length > 2) {
      searchGoogleMapsByName(value);
    }
  };

  useEffect(() => {
    if (mapDivRef.current) {
      const height = mapDivRef.current.clientHeight;
      // Set the map height to the height of the map div.
      setMapHeight(height);
    }
  }, []);

  return (
    <>
      <Message>
        <Message.Header>Note</Message.Header>
        <Message.List>
          <Message.Item>Drag the marker to the location of the experience.</Message.Item>
          <Message.Item>Use the search bar to find the location of the experience.</Message.Item>
        </Message.List>
      </Message>
      {location.hasError() && (
        <Message error visible>
          {location.error}
        </Message>
      )}
      <div
        className={"experience-location-div"}
        style={{
          height: height ?? "50vh",
          maxHeight: "400px",
        }}
      >
        <Search
          fluid
          input={{ fluid: true }}
          placeholder="Search for a location"
          value={query}
          onSearchChange={handleSearchChange}
          onResultSelect={handleResultSelect}
          results={results}
        />
        <div className={"experience-location-vertical-space-div"}></div>
        <div ref={mapDivRef} className={"experience-location-map-div"}>
          <GoogleMap
            markerPosition={markerPosition}
            setMarkerPosition={setMarkerPosition}
            height={mapHeight}
            onMapReady={handleMapReady}
            onDragEnd={handleDragEnd}
            zoomControl={true}
          />
        </div>
      </div>
    </>
  );
};
