import { Button, Card, Form, Icon, Input, Label } from "semantic-ui-react";
import React, { useEffect, useState } from "react";

export const ExperienceFormProductAddForm = ({ products, onProductAdd }) => {
  // A state for the new product
  const [product, setProduct] = useState({
    name: {
      value: "",
      error: null,
      valid: false,
    },
    duration: {
      value: "",
      error: null,
      valid: false,
    },
    price: {
      value: "",
      error: null,
      valid: false,
    },
    description: {
      value: "",
      error: null,
      valid: false,
    },
    valid: false,
  });

  // An effect to check if the new product is valid.
  useEffect(() => {
    // If the new product is not defined, return
    if (!product) {
      return;
    }
    // List the fields to check
    const fields = ["name", "duration", "price", "description"];
    // If all the fields are valid, set the new product to valid
    const valid = fields.every((field) => {
      return product[field].valid;
    });
    if (valid !== product.valid) {
      setProduct({
        ...product,
        valid: valid,
      });
    }
  }, [product]);

  const handleNameChange = (event) => {
    const name = event.target.value;
    let error = null;
    // If the name is empty, raise an error
    if (name.length === 0) {
      error = "Must not be empty";
    }
    // If a product in the list has the same name, raise an error
    if (products.some((product) => product.name === name)) {
      error = "Must be unique";
    }
    // If the length of the name is greater than 80, raise an error
    if (name.length > 80) {
      error = "Must be less than 80 characters";
    }
    setProduct({
      ...product,
      name: {
        value: name,
        error: error,
        valid: error === null,
      },
    });
  };

  const handleDurationChange = (event) => {
    let duration = event.target.value;
    let error = null;
    if (duration.length === 0) {
      error = "Please enter a duration";
    }
    // Split duration in integer and decimal parts
    let [integer, decimal] = duration.split(".");
    // If the integer part is zero, truncate it
    if (integer && parseInt(integer) === 0) {
      integer = "0";
    }
    // If the decimal is zero, truncate it
    if (decimal && parseInt(decimal) === 0) {
      decimal = "0";
    }
    // If the integer part is longer than 2 characters, truncate it
    if (integer && integer.length > 2) {
      integer = integer.substring(0, 2);
    }
    // If the decimal part is longer than 2 characters, truncate it
    if (decimal && decimal.length > 2) {
      decimal = decimal.substring(0, 2);
    }
    // If there's a decimal part, concatenate it to the integer part
    if (decimal) {
      duration = integer + "." + decimal;
    }
    // If there's no decimal part, just use the integer part
    else {
      duration = integer;
    }
    // If the duration is greater than 24, truncate it
    if (duration && parseFloat(duration) > 24) {
      duration = "24";
    }
    // If the duration is 0, raise an error
    if (duration && parseFloat(duration) === 0) {
      error = "Duration must be greater than 0";
    }
    // Update the duration of the product
    setProduct({
      ...product,
      duration: {
        value: duration,
        error: error,
        valid: error === null,
      },
    });
  };

  const handleDescriptionChange = (event) => {
    // Initialize the error to null
    let error = null;
    // Retrieve the description from the event
    const description = event.target.value;
    // If the description is empty, raise an error
    if (description.length === 0) {
      error = "Please enter a description";
    }
    // If the description is longer than 120 characters, raise an error
    else if (description.length > 120) {
      error = "Must be less than 120 characters";
    }
    // Update the description of the product
    setProduct({
      ...product,
      description: {
        value: description,
        error: error,
        valid: error === null,
      },
    });
  };

  const handlePriceChange = (event) => {
    // Retrieve the price from the event
    let price = event.target.value;
    // Initialize the error to null
    let error = null;
    // If the price is empty, raise an error
    if (price.length === 0) {
      error = "Please enter a price";
    }
    // Split price in integer and decimal parts
    let [integer, decimal] = price.split(".");
    // If the integer part is zero, truncate it
    if (integer && parseInt(integer) === 0) {
      integer = "0";
    }
    // If the decimal is zero, truncate it
    if (decimal && parseInt(decimal) === 0) {
      decimal = "0";
    }
    // If the decimal part is longer than 2 characters, truncate it
    if (decimal && decimal.length > 2) {
      decimal = decimal.substring(0, 2);
    }
    // If there's a decimal part, concatenate it to the integer part
    if (decimal) {
      price = integer + "." + decimal;
    }
    // If there's no decimal part, just use the integer part
    else {
      price = integer;
    }
    // If the price is 0, raise an error
    if (price && parseFloat(price) === 0) {
      error = "Must be greater than 0";
    }
    // Update the price of the product
    setProduct({
      ...product,
      price: {
        value: price,
        error: error,
        valid: error === null,
      },
    });
  };

  const handleAddClick = () => {
    // If the product is not valid, return
    if (!product.valid) {
      return;
    }
    // Map the product to the format expected by the API
    const newProduct = {
      name: product.name.value,
      duration: parseFloat(product.duration.value),
      price: parseFloat(product.price.value),
      description: product.description.value,
    };
    // Propagate the product to the parent component
    onProductAdd(newProduct);
  };

  return (
    <Card fluid>
      <Card.Content>
        <Form.Input
          error={product.name.error}
          fluid
          label="Name"
          placeholder="Awesome Experience"
          required
          content={product.name.value}
          onChange={handleNameChange}
        />
        <Form.Field error={product.duration.error !== null} required>
          <label>Duration</label>
          <Input labelPosition={"right"} type={"number"} placeholder={"0-24"} onChange={handleDurationChange}>
            <input type={"number"} value={product.duration.value} />
            <Label basic>hour(s)</Label>
          </Input>
          {product.duration.error && (
            <Label pointing prompt>
              {product.duration.error}
            </Label>
          )}
        </Form.Field>
        <Form.Input
          error={product.description.error}
          label="Description"
          placeholder="Less than 120 characters"
          control="textarea"
          content={product.description.value}
          required
          onChange={handleDescriptionChange}
        />
        <Form.Field error={product.price.error !== null} required>
          <Input labelPosition={"left"} type={"number"} placeholder={"0.00"} onChange={handlePriceChange}>
            <Label basic>$</Label>
            <input value={product.price.value} />
          </Input>
          {product.price.error && (
            <Label pointing prompt>
              {product.price.error}
            </Label>
          )}
        </Form.Field>
      </Card.Content>
      <Card.Content extra textAlign={"center"}>
        <Button
          active={product.valid}
          icon
          onClick={handleAddClick}
          color={product.valid ? "teal" : "grey"}
          type={"button"}
        >
          <Icon name={"add"} />
          Add
        </Button>
      </Card.Content>
    </Card>
  );
};
