import { StringField } from "../string/field";
import { Field } from "../field";
import { ExperienceState } from "./state";

export class ExperienceContactField extends Field {
  constructor() {
    super({
      name: new StringField(),
      phoneNumber: new StringField(),
      email: new StringField(),
      websiteUrl: new StringField(),
      facebookUrl: new StringField(),
      instagramUrl: new StringField(),
      whatsApp: new StringField(),
    });
  }
}

export class ExperiencePaymentMethodsField extends Field {
  constructor() {
    super({
      sinpeMovil: new Field(false, { label: "SINPE Móvil" }),
      masterCard: new Field(false, { label: "Mastercard" }),
      americanExpress: new Field(false, { label: "American Express" }),
      visa: new Field(false, { label: "Visa" }),
      bitcoin: new Field(false, { label: "Bitcoin" }),
      payPal: new Field(false, { label: "PayPal" }),
      bankTransfer: new Field(false, { label: "Bank Transfer" }),
      cash: new Field(false, { label: "Cash" }),
    });
  }
}

export class ExperienceFeaturesField extends Field {
  constructor() {
    super({
      accommodation: new Field(false, { label: "Lodging" }),
      adultsOnly: new Field(false, { label: "Adults Only" }),
      allTerrainVehicleOnly: new Field(false, { label: "4x4 Only" }),
      birdWatching: new Field(false, { label: "Bird Watching" }),
      camping: new Field(false, { label: "Camping" }),
      carbonNeutral: new Field(false, { label: "Carbon Neutral" }),
      evCharger: new Field(false, { label: "EV Charger" }),
      foodDrink: new Field(false, { label: "Food & Drink" }),
      internet: new Field(false, { label: "Internet" }),
      parking: new Field(false, { label: "Parking" }),
      petFriendly: new Field(false, { label: "Pet Friendly" }),
      publicTransport: new Field(false, { label: "Public Transport" }),
      securityLockers: new Field(false, { label: "Security Lockers" }),
      showers: new Field(false, { label: "Showers" }),
      smokingArea: new Field(false, { label: "Smoking Area" }),
      toilets: new Field(false, { label: "Toilets" }),
      wheelchairAccessible: new Field(false, { label: "Wheelchair Accessible" }),
    });
  }
}

class ExperienceScheduleField extends Field {
  constructor() {
    super({
      timetables: new Field([]),
      stayMinutes: new Field(30),
    });
  }
}

class ExperienceLocationField extends Field {
  constructor() {
    super({
      latitude: new Field(9.93593727277584),
      longitude: new Field(-84.10095092339176),
    });
  }
}

class ExperienceStatusField extends Field {
  constructor() {
    super({
      state: new StringField({ value: ExperienceState.DRAFT }),
      draftId: new StringField(),
      publicationId: new StringField(),
    });
  }
}

class ExperienceDestinationField extends Field {
  constructor() {
    super({
      id: new Field(null),
      name: new StringField(),
    });
  }
}

export class ExperienceField extends Field {
  constructor(
    value = {
      id: new StringField(),
      contact: new ExperienceContactField(),
      paymentMethods: new ExperiencePaymentMethodsField(),
      features: new ExperienceFeaturesField(),
      schedule: new ExperienceScheduleField(),
      location: new ExperienceLocationField(),
      images: new Field([]),
      products: new Field([]),
      status: new ExperienceStatusField(),
      description: new StringField(),
      topics: new Field([]),
      destination: new ExperienceDestinationField(),
    },
    options,
  ) {
    super(value, options);
  }

  isPublication() {
    // If this status is not "published"
    if (this.value.status.value.state.value !== ExperienceState.PUBLISHED) {
      return false;
    }
    // If the ID of the experience is not the same as the publication ID
    if (this.value.id.value !== this.value.status.value.publicationId.value) {
      return false;
    }
    return true;
  }
}
