import React, { useEffect, useState } from "react";
import { ExperienceFormCheckbox } from "./ExperienceFormCheckbox/ExperienceFormCheckbox";
import { ExperienceFormDoubleRangeInput } from "./ExperienceFormDoubleRangeInput/ExperienceFormDoubleRangeInput";
import { Header, Message } from "semantic-ui-react";
import { updateLocalExperienceValue } from "../../../../redux/experienceDucks";
import { getHourList, getStayHourList } from "../../../../constants/hours";
import { useDispatch, useSelector } from "react-redux";

export const ExperienceFormSchedule = () => {
  // The metadata of the weekdays.
  const [weekdays] = useState([
    { index: 1, name: "Monday" },
    { index: 2, name: "Tuesday" },
    { index: 3, name: "Wednesday" },
    { index: 4, name: "Thursday" },
    { index: 5, name: "Friday" },
    { index: 6, name: "Saturday" },
    { index: 0, name: "Sunday" },
  ]);

  const [hoursList] = useState(getHourList());

  const [stayHourList] = useState(getStayHourList());

  // A selector for retrieving the schedule of the experience from the store.
  let schedule = useSelector((state) => state.experiences.experience.value.schedule);

  // A hook to dispatch actions to the redux store.
  const dispatch = useDispatch();

  // A state variable that stores the schedule days.
  const [scheduleDays, setScheduleDays] = useState({});

  const formatHour = (hour) => {
    if (hour === "23:59") {
      return hour;
    }
    // Split the hour into hours and minutes.
    const [hours, minutes] = hour.split(":");
    // Parse minutes to integer.
    const minutesInt = parseInt(minutes);
    if (minutesInt <= 15) {
      return hours + ":00";
    }
    if (minutesInt <= 45) {
      return hours + ":30";
    }
    let nextHour = parseInt(hours) + 1;
    if (nextHour < 10) {
      nextHour = "0" + nextHour;
    }
    return nextHour + ":00";
  };

  // An effect for loading the schedule days from the experience if available.
  useEffect(() => {
    const scheduleDays = weekdays
      .map((day) => {
        return {
          id: null,
          day: day.name,
          index: day.index,
          active: false,
          hours: [16, 32],
          hourFormatted: ["08:00", "16:00"],
          _destroy: false,
          _update: false,
        };
      })
      .reduce((acc, item) => {
        acc[item.index] = item;
        return acc;
      }, {});
    schedule.value.timetables.value.forEach((timetable) => {
      const itemOpenHour = formatHour(timetable.open_hour);
      const itemCloseHour = formatHour(timetable.close_hour);
      const startHour = getHourList().find((hour) => hour.text === itemOpenHour);
      const closeHour = getHourList().find((hour) => hour.text === itemCloseHour);
      const day = scheduleDays[timetable.wday];
      day.id = timetable.id;
      day.active = true;
      day.hourFormatted = [timetable.open_hour, timetable.close_hour];
      day.hours = [startHour.index, closeHour.index];
    });
    setScheduleDays(scheduleDays);
  }, []);

  const renderHoursActive = (index) => {
    const day = scheduleDays[index];
    return (
      <Header className="experience-form-active-hours-header">
        {day.hourFormatted[0] + " - " + day.hourFormatted[1]}
      </Header>
    );
  };

  const fromDayScheduleToTimetable = (schedule) => {
    const timetable = {};
    if (schedule.id !== null) {
      timetable.id = schedule.id;
    }
    if (schedule._destroy) {
      timetable._destroy = true;
    }
    if (schedule._update) {
      timetable._update = true;
    }
    timetable.wday = schedule.index;
    timetable.open_hour = schedule.hourFormatted[0];
    timetable.close_hour = schedule.hourFormatted[1];
    return timetable;
  };

  async function updateExperienceScheduleTimetables(schedules) {
    // Retrieve the values of the fields in timetables.
    const newTimetables = Object.values(schedules)
      .filter((schedule) => schedule.id || (schedule.active && !schedule.id))
      .map(fromDayScheduleToTimetable);
    // Update the value of the schedule.
    schedule = await schedule.setValue({
      ...schedule.value,
      timetables: await schedule.value.timetables.setValue(newTimetables),
    });
    await dispatch(updateLocalExperienceValue({ schedule: schedule }));
  }

  // A callback for handling the activation of a schedule day.
  const handleScheduleDayActiveChange = async (index) => {
    // Get the schedule day of the index.
    const scheduleDay = scheduleDays[index];
    // If the schedule day has an identifier and is active, mark it as ready to destroy.
    if (scheduleDay.id && scheduleDay.active) {
      scheduleDay._destroy = true;
    }
    // If the schedule day has an identifier and is inactive, remove the destroy mark.
    if (scheduleDay.id && !scheduleDay.active && scheduleDay._destroy) {
      scheduleDay._destroy = false;
    }
    // Toggle the active state of the schedule day.
    scheduleDay.active = !scheduleDay.active;
    // Update the schedule days in the state.
    setScheduleDays({ ...scheduleDays });
    // Update the experience in the store.
    await updateExperienceScheduleTimetables(scheduleDays);
  };

  const handleScheduleDayHoursChange = (index) => async (e, newValue) => {
    // Clone the schedule days.
    const newScheduleDays = { ...scheduleDays };
    // Get the start and final hours of the new value.
    const startHour = hoursList.find((hour) => hour.index === newValue[0]);
    const finalHour = hoursList.find((hour) => hour.index === newValue[1]);
    // Get the schedule day of the index.
    const scheduleDay = newScheduleDays[index];
    // Set the hours of the schedule day of the index to the new value.
    scheduleDay["hours"] = newValue;
    scheduleDay["hourFormatted"] = [startHour.text, finalHour.text];
    // If the schedule has an identifier, mark it as ready to update.
    if (scheduleDay.id) {
      scheduleDay._update = true;
    }
    // Update the days in the state.
    setScheduleDays(newScheduleDays);
    // Update the experience in the store.
    await updateExperienceScheduleTimetables(newScheduleDays);
  };

  function fromStayMinutesToIndex(minutes) {
    return stayHourList.find((hour) => hour.minutes === minutes).index;
  }

  const renderAverageStayDuration = () => {
    const averageStayIndex = fromStayMinutesToIndex(schedule.value.stayMinutes.value);
    const time = stayHourList.find((hour) => hour.index === averageStayIndex);
    if (Object.entries(stayHourList).length) {
      let timeUnit;
      if (time.minutes === 60) {
        timeUnit = "hour";
      } else if (time.minutes > 30) {
        timeUnit = "hours";
      } else {
        timeUnit = "minutes";
      }
      return <Header className="experience-form-active-hours-header">{`${time.text} ${timeUnit}`}</Header>;
    }
    return <></>;
  };

  function fromAverageStayIndexToMinutes(index) {
    return stayHourList.find((hour) => hour.index === index).minutes;
  }

  const handleAverageStayChange = async (e, index) => {
    // Update the value of the schedule.
    schedule = await schedule.setValue({
      ...schedule.value,
      stayMinutes: await schedule.value.stayMinutes.setValue(fromAverageStayIndexToMinutes(index)),
    });
    await dispatch(updateLocalExperienceValue({ schedule: schedule }));
  };

  return (
    <>
      {schedule.value.timetables.hasError() && <Message content={schedule.value.timetables.error} error visible />}
      {Object.values(scheduleDays).length > 0 &&
        weekdays
          .map((day) => scheduleDays[day.index])
          .map((item, index) => (
            <div key={index}>
              <ExperienceFormCheckbox
                label={item.day}
                checked={item.active}
                onChange={handleScheduleDayActiveChange.bind(this, item.index)}
              />
              {item.active && (
                <>
                  {renderHoursActive(item.index)}
                  <ExperienceFormDoubleRangeInput
                    name={item.day}
                    min={0}
                    max={48}
                    step={1}
                    value={item.hours}
                    onChange={handleScheduleDayHoursChange(item.index)}
                  />
                </>
              )}
            </div>
          ))}
      <Header
        style={{
          fontSize: "14px",
          fontWeight: 600,
          textTransform: "uppercase",
        }}
      >
        Average stay
      </Header>
      {renderAverageStayDuration()}
      <ExperienceFormDoubleRangeInput
        min={1}
        max={16}
        step={1}
        track={false}
        value={fromStayMinutesToIndex(schedule.value.stayMinutes.value)}
        onChange={handleAverageStayChange}
      />
    </>
  );
};
