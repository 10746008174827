import { FieldValidator } from "../validator";
import { Booleans } from "../boolean";
import { DistanceMatrix } from "../distance-matrix";
import { getFileSizeFromObjectUrlInMegabytes } from "../file";
import {
  DescriptionFieldValidator,
  EmailAddressFieldValidator,
  NameFieldValidator,
  PhoneNumberFieldValidator,
  WebAddressFieldValidator,
} from "../string/validator";

export class ExperiencePaymentMethodsFieldValidator extends FieldValidator {
  async getError(field) {
    // If the value doest not have at least one payment method
    // Return "Please select at least one payment method"
    if (!this.hasAtLeastOnePaymentMethod(field.value)) {
      return "Please select at least one payment method";
    }
    // Else, return null
    return null;
  }

  hasAtLeastOnePaymentMethod(value) {
    return Booleans.atLeastOne([
      value.sinpeMovil.value,
      value.masterCard.value,
      value.americanExpress.value,
      value.visa.value,
      value.bitcoin.value,
      value.payPal.value,
      value.bankTransfer.value,
      value.cash.value,
    ]);
  }
}

export class ExperienceTimetablesFieldValidator extends FieldValidator {
  async getError(field) {
    // If the value doest not have at least one timetable
    // Return "Please add at least one day of the week"
    if (field.value.length === 0) {
      return "Please add at least one day of the week";
    }
    // Else, return null
    return null;
  }
}

export class ExperienceLocationFieldValidator extends FieldValidator {
  async getError(field) {
    // If the coordinates are not reachable
    // Return "Please select a reachable location"
    const isReachable = await DistanceMatrix.isReachable(field.value.latitude.value, field.value.longitude.value);
    if (!isReachable) {
      return "Please select a reachable location";
    }
    // Else, return null
    return null;
  }
}

export class ExperienceImagesFieldValidator extends FieldValidator {
  constructor(options) {
    super(options);
    // Set field "minLength" to options.minLength
    // If options.minLength is not set, set it to 0
    this.minLength = options.minLength ?? 0;
  }

  async getError(field) {
    // Filter the images that are not marked for deletion.
    let images = field.value.filter((value) => !value._destroy);
    // If less than minLength images
    if (images.length < this.minLength) {
      // If minLength is 1, return "Please add at least 1 image"
      if (this.minLength === 1) {
        return "Please add at least 1 image";
      }
      // Return "Please add at least <minLength> images"
      return `Please add at least ${this.minLength} images`;
    }
    for (const image of images) {
      // If the image is not a blob, skip it.
      if (!image.pictureUrl.startsWith("blob:")) {
        continue;
      }
      // Get the size of the image in megabytes.
      const size = await getFileSizeFromObjectUrlInMegabytes(image.pictureUrl);
      // If the size is greater than 3 MB
      // Return "Please upload images smaller than 3 MB"
      if (size > 3) {
        return "Please upload images smaller than 3 MB";
      }
    }
    // Else, return null
    return null;
  }
}

export class ExperienceTopicsFieldValidator extends FieldValidator {
  async getError(field) {
    // If the value doest not have at least three topics
    // Return "Please add at least three topics"
    if (field.value.length < 3) {
      return "Please add at least three topics";
    }
    // Else, return null
    return null;
  }
}

export class ExperienceDestinationFieldValidator extends FieldValidator {
  async getError(field) {
    // If the ID is not set
    // Return "Please select a destination"
    if (!field.value.id.hasValue()) {
      return "Please select a destination";
    }
    // Else, return null
    return null;
  }
}

class ExperienceFieldValidator extends FieldValidator {
  constructor(options = {}) {
    super(options);
    this.contact = {
      name: new NameFieldValidator(options),
      phoneNumber: new PhoneNumberFieldValidator(options),
      email: new EmailAddressFieldValidator(options),
      websiteUrl: new WebAddressFieldValidator(options),
      facebookUrl: new WebAddressFieldValidator({
        ...options,
        hostname: "facebook.com",
      }),
      instagramUrl: new WebAddressFieldValidator({
        ...options,
        hostname: "instagram.com",
      }),
      whatsApp: new PhoneNumberFieldValidator(options),
    };
    this.paymentMethods = new ExperiencePaymentMethodsFieldValidator(options);
    this.schedule = {
      timetables: new ExperienceTimetablesFieldValidator(options),
    };
    this.location = new ExperienceLocationFieldValidator(options);
    this.images = new ExperienceImagesFieldValidator(options);
    this.topics = new ExperienceTopicsFieldValidator(options);
    this.destination = new ExperienceDestinationFieldValidator(options);
    this.description = new DescriptionFieldValidator(options);
  }

  async getError(field) {
    // Declare variables "invalids" as an empty array
    const invalids = [];
    // If the contact is not valid
    // Add "contact" to "invalids"
    if (!field.value.contact.isValid()) {
      invalids.push("contact");
    }
    // If the payment methods are not valid
    // Add "payment methods" to "invalids"
    if (!field.value.paymentMethods.isValid()) {
      invalids.push("payment methods");
    }
    // If the schedule is not valid
    // Add "schedule" to "invalids"
    if (!field.value.schedule.isValid()) {
      invalids.push("schedule");
    }
    // If the location is not valid
    // Add "location" to "invalids"
    if (!field.value.location.isValid()) {
      invalids.push("location");
    }
    // If the images are not valid
    // Add "images" to "invalids"
    if (!field.value.images.isValid()) {
      invalids.push("images");
    }
    // If the products are not valid
    // Add "products" to "invalids"
    if (!field.value.products.isValid()) {
      invalids.push("products");
    }
    // If the description is not valid
    // Add "description" to "invalids"
    if (!field.value.description.isValid()) {
      invalids.push("description");
    }
    // If the topics are not valid
    // Add "topics" to "invalids"
    if (!field.value.topics.isValid()) {
      invalids.push("topics");
    }
    // If the destination is not valid
    // Add "destination" to "invalids"
    if (!field.value.destination.isValid()) {
      invalids.push("destination");
    }
    // If there are invalid fields
    // Return "Please verify your [invalids]"
    if (invalids.length > 0) {
      let error = "Please verify your ";
      // Separate the last invalid with an 'and'
      if (invalids.length > 1) {
        invalids[invalids.length - 1] = "and " + invalids[invalids.length - 1];
      }
      error += invalids.join(", ");
      return error;
    }
    // Else, return null
    return null;
  }

  // Override method "isValid"
  isValid(field) {
    // True, if super is valid and the field is modified
    return super.isValid(field) && field.isModified();
  }

  attachTo(experience) {
    // Experience
    experience.setValidator(this);
    // Contact
    experience.value.contact.value.name.setValidator(this.contact.name);
    experience.value.contact.value.phoneNumber.setValidator(this.contact.phoneNumber);
    experience.value.contact.value.email.setValidator(this.contact.email);
    experience.value.contact.value.websiteUrl.setValidator(this.contact.websiteUrl);
    experience.value.contact.value.facebookUrl.setValidator(this.contact.facebookUrl);
    experience.value.contact.value.instagramUrl.setValidator(this.contact.instagramUrl);
    experience.value.contact.value.whatsApp.setValidator(this.contact.whatsApp);
    // Destination
    experience.value.destination.setValidator(this.destination);
    // Description
    experience.value.description.setValidator(this.description);
    // Images
    experience.value.images.setValidator(this.images);
    // Location
    experience.value.location.setValidator(this.location);
    // Payment Methods
    experience.value.paymentMethods.setValidator(this.paymentMethods);
    // Schedule
    experience.value.schedule.value.timetables.setValidator(this.schedule.timetables);
    // Topics
    experience.value.topics.setValidator(this.topics);
  }
}

export class DraftExperienceFieldValidator extends ExperienceFieldValidator {
  constructor(options = {}) {
    super(options);
    this.contact.name.required = true;
  }
}

export class ReadyForReviewExperienceFieldValidator extends DraftExperienceFieldValidator {
  constructor(options = {}) {
    super(options);
    this.schedule.timetables.required = true;
    this.location.required = true;
    this.images.required = true;
    this.images.minLength = 3;
  }
}

export class ReadyToPublishExperienceFieldValidator extends ReadyForReviewExperienceFieldValidator {
  constructor(options = {}) {
    super(options);
    this.destination.required = true;
    this.topics.required = true;
    this.description.required = true;
  }
}
