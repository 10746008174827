import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, withRouter } from "react-router-dom";
import { resetActiveStep, sendResetPasswordEmail } from "../../redux/userDucks";
import { Segment } from "semantic-ui-react";
import PasswordBase from "./PasswordBase";
import FormDataEmail from "./FormDataEmail/ForgotDataEmail";
import * as ROUTES from "../../constants/routes";
import "./ForgotPassword.css";
import FormDataPasswordReset from "./FormDataPasswordReset/FormDataPasswordReset";

const ForgotPassword = (props) => {
  const isLogged = useSelector((store) => store.authentication.isLogged);
  const forgetPasswordEmailSent = useSelector((store) => store.authentication.forgetPasswordEmailSent);
  const [emailError, setEmailError] = useState({});
  const [user, setUser] = useState({
    email: "",
    code: "",
    terms: false,
    password: "",
    confirmPassword: "",
  });
  const dispatch = useDispatch();

  const [query] = useState(new URLSearchParams(useLocation().search));

  const validateEmail = (email) => {
    let error = "";
    if (email === null || email.length === 0 || /^\s+$/.test(email)) {
      error = "Please enter an email";
    } else if (!validateEmailFormat(email)) {
      error = "Please enter a valid email";
    }
    return error;
  };

  const validateEmailFormat = (email) => {
    const regex = /^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    return regex.test(email);
  };

  const handleEmailSubmit = async (event) => {
    event.preventDefault();
    const emailError = validateEmail(user.email);
    emailError ? setEmailError({ emailError }) : dispatch(sendResetPasswordEmail(user.email));
  };

  const handleEmailChange = (event, data) => {
    if (data) {
      const { name, checked } = data;
      setUser({ ...user, [name]: checked });
    } else {
      const { name, value } = event.target;
      setUser({ ...user, [name]: value });
    }
  };

  useEffect(() => {
    const isEmailComplete = validateEmailFormat(user.email);
    setEmailError({ isEmailComplete });
  }, [user]);

  useEffect(() => {
    if (isLogged) {
      props.history.push(ROUTES.CONSOLE);
    } else if (forgetPasswordEmailSent) {
      props.history.push(ROUTES.LOGIN);
    }
  }, [isLogged, props.history, forgetPasswordEmailSent]);

  useEffect(() => {
    if (!isLogged) dispatch(resetActiveStep());
  }, [dispatch, isLogged]);

  return (
    <PasswordBase>
      <Segment className="login-forgot-password-data-container">
        {query.has("token") ? (
          <FormDataPasswordReset token={query.get("token")} />
        ) : (
          <FormDataEmail
            user={user}
            handleOnSubmit={handleEmailSubmit}
            handleOnChange={handleEmailChange}
            error={emailError}
          />
        )}
      </Segment>
    </PasswordBase>
  );
};

export default withRouter(ForgotPassword);
