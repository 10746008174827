import axios from "axios";
import { getState } from "../redux/store";

const getHeaders = () => {
  const state = getState();
  const token = state.authentication.user?.token ?? "";
  return {
    headers: {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`,
    },
  };
};

export const getAxios = async (url, params) => {
  return await axios.get(url, { params, ...getHeaders() });
};

export const postAxios = async (url, data) => {
  return await axios.post(url, data, getHeaders());
};

export const patchAxios = async (url, data) => {
  return await axios.patch(url, data, getHeaders());
};

export const putAxios = async (url, data) => {
  return await axios.put(url, data, getHeaders());
};

export const deleteAxios = async (url) => {
  return await axios.delete(url, getHeaders());
};
