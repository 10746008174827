import React, { useEffect, useState } from "react";
import { Button, ButtonGroup, Dropdown } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { createExperience, updateExperience, updateLocalExperience } from "../../../../redux/experienceDucks";
import { ExperienceState } from "../../../../v2/experience/state";
import {
  DraftExperienceFieldValidator,
  ReadyForReviewExperienceFieldValidator,
  ReadyToPublishExperienceFieldValidator,
} from "../../../../v2/experience/validator";
import { useHistory } from "react-router-dom";

const Options = {
  SAVE_DRAFT: {
    experienceState: ExperienceState.DRAFT,
    key: "save-draft",
    icon: "edit",
    text: "Save draft",
    validator: new DraftExperienceFieldValidator(),
  },
  SUBMIT_FOR_REVIEW: {
    experienceState: ExperienceState.READY_FOR_REVIEW,
    key: "submit-for-review",
    icon: "eye",
    text: "Submit for review",
    validator: new ReadyForReviewExperienceFieldValidator(),
  },
  PUBLISH: {
    experienceState: ExperienceState.READY_TO_PUBLISH,
    key: "publish",
    icon: "rocket",
    text: "Publish",
    validator: new ReadyToPublishExperienceFieldValidator(),
  },
  GO_TO_PUBLICATION: {
    key: "go-to-publication",
    icon: "external",
    text: "Go to publication",
  },
  EDIT: {
    key: "edit",
    text: "Edit",
  },
};

const ExperienceFormSubmitButton = () => {
  // Select the experience from the store
  const experience = useSelector((state) => state.experiences.experience);

  // Select the user from the store
  const user = useSelector((state) => state.authentication.user);

  // Get the dispatch hook
  const dispatch = useDispatch();

  // Get the history hook
  const history = useHistory();

  // Get the active option from the state
  const [activeOption, setActiveOption] = useState(null);

  // Get the options from the state
  const [options, setOptions] = useState(null);

  // Get whether to attach validator from the state
  const [isAttachValidator, setIsAttachValidator] = useState(false);

  function pushDraftToHistory() {
    // Push the location of the draft to the history
    history.push(`/experiences/${experience.value.status.value.draftId.value}/edit`);
  }

  const handleButtonClick = async () => {
    // If the experience is a publication
    if (experience.isPublication()) {
      // Push the history to the draft
      pushDraftToHistory();
      return;
    }
    // If the experience doesn't have an ID
    if (!experience.value.id.hasValue()) {
      // Dispatch the create experience action
      await dispatch(createExperience());
    } else {
      // Dispatch the update experience action
      await dispatch(updateExperience());
    }
    setIsAttachValidator(true);
  };

  const initializeOptions = () => {
    // Declare the options
    let options;
    // If the experience state is publication
    if (experience.isPublication()) {
      options = {
        edit: { ...Options.EDIT },
      };
      setActiveOption(options.edit);
      setOptions(options);
      return;
    }
    // Set the base options
    options = {
      saveDraft: { ...Options.SAVE_DRAFT },
      submitForReview: { ...Options.SUBMIT_FOR_REVIEW },
    };
    // If the user is an admin
    if (user.isAdmin) {
      // Add the publish option
      options.publish = { ...Options.PUBLISH };
    }
    // If the experience publication ID is set
    if (experience.value.status.value.publicationId.hasValue()) {
      // Add the go to publication option
      options.goToPublication = { ...Options.GO_TO_PUBLICATION };
    }
    // Declare the active option
    let activeOption = null;
    // If the experience state is "draft"
    if (experience.value.status.value.state.value === ExperienceState.DRAFT) {
      // Set saveDraft to active
      activeOption = options.saveDraft;
    }
    // If the experience state is "ready for review" and the user is an admin
    else if (experience.value.status.value.state.value === ExperienceState.READY_FOR_REVIEW && user.isAdmin) {
      // Set "publish" to active
      activeOption = options.publish;
    }
    // If the experience state is "ready for review" and the user is not an admin
    else if (experience.value.status.value.state.value === ExperienceState.READY_FOR_REVIEW && !user.isAdmin) {
      // Set submitForReview to active
      activeOption = options.submitForReview;
    }
    // Else
    else {
      // Throw error "Invalid experience state"
      throw new Error("Invalid experience state");
    }
    // Update the active option in the state
    setActiveOption(activeOption);
    // Update the options in the state
    setOptions(options);
  };

  const isSubmitEnabled = () => {
    // If validator in the active option is not set
    if (!activeOption || !activeOption.validator) {
      // Return true
      return true;
    }
    // Whether the experience is valid
    return !isAttachValidator && experience.isValid();
  };

  async function attachValidatorToExperience() {
    // If the active option is not set
    if (!activeOption) {
      // Throw error "Active option not found"
      throw new Error("Active option not found");
    }
    // Attach the validator to the experience
    activeOption.validator.attachTo(experience);
    // Refresh the error messages
    await experience.validateDeep();
    // Update the experience in the store
    await dispatch(updateLocalExperience(experience.duplicate()));
    // Do not attach the validator again
    setIsAttachValidator(false);
  }

  const setExperienceState = async (state) => {
    // Set the experience state to the new state
    await experience.value.status.value.state.setValue(state);
    // Dispatch the update local experience action
    await dispatch(updateLocalExperience(experience.duplicate()));
  };

  function pushPublicationToHistory() {
    // Push the history to "/experiences/:publicationId/edit"
    history.push(`/experiences/${experience.value.status.value.publicationId.value}/edit`);
  }

  const handleDropdownClick = async (e, data) => {
    // If the active option is clicked
    if (data.value === activeOption.value) {
      // Return
      return;
    }
    // If option is "save draft"
    if (data.value === Options.SAVE_DRAFT.key) {
      // Set the active option to save draft
      setActiveOption(options.saveDraft);
    }
    // If option is "submit for review"
    else if (data.value === Options.SUBMIT_FOR_REVIEW.key) {
      // Set the active option to submit for review
      setActiveOption(options.submitForReview);
    }
    // If option is "publish"
    else if (data.value === Options.PUBLISH.key) {
      // Set the active option to publish
      setActiveOption(options.publish);
    }
    // If option is "go to publication"
    else if (data.value === Options.GO_TO_PUBLICATION.key) {
      // Push publication to history
      pushPublicationToHistory();
    }
    // Else, throw error "Invalid data value"
    else {
      throw new Error(`Invalid data value: ${data.value}`);
    }
  };

  const getButtonText = () => {
    // If the active option is not set
    if (!activeOption) {
      // Return empty string
      return "";
    }
    // Return the active option text
    return activeOption.text;
  };

  const getDropdownOptions = () => {
    // If the options are not set
    if (!options) {
      // Return empty array
      return [];
    }
    // For each option
    return Object.values(options).map((option) => {
      // Return the option as an object
      return {
        key: option.key,
        value: option.key,
        icon: option.icon,
        text: option.text,
        active: option === activeOption,
      };
    });
  };

  useEffect(() => {
    // Initialize the options
    initializeOptions();
  }, []);

  useEffect(() => {
    // If not attach validator, return
    if (!isAttachValidator) {
      return;
    }
    // Attach the validator to the experience
    attachValidatorToExperience();
  }, [isAttachValidator]);

  useEffect(() => {
    // If the active option is not set, return
    if (!activeOption) {
      return;
    }
    // If the active option state is not set, return
    if (!activeOption.experienceState) {
      return;
    }
    // If the experience state equals the active option state
    if (experience.value.status.value.state.value === activeOption.experienceState) {
      // Attach the validator to the experience
      setIsAttachValidator(true);
      return;
    }
    // Set the experience state to the active option state
    setExperienceState(activeOption.experienceState)
      // Attach the validator to the experience
      .then(() => setIsAttachValidator(true));
  }, [activeOption]);

  const isShowDropdown = () => {
    // If the length of the options is less than 2
    return options && Object.keys(options).length > 1;
  };

  return (
    <ButtonGroup
      color={isSubmitEnabled() ? "teal" : "grey"}
      style={{
        width: "100%",
        height: "96px",
        margin: "0",
        padding: "0",
        display: "flex",
      }}
    >
      <Button
        type="button"
        disabled={!isSubmitEnabled()}
        onClick={handleButtonClick}
        style={{
          fontSize: "18px",
          flex: "1",
        }}
      >
        {getButtonText()}
      </Button>
      {isShowDropdown() && (
        <Dropdown
          className="button icon"
          floating
          options={getDropdownOptions()}
          selectOnBlur={false}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            flex: "0 0 64px",
          }}
          // Hide the text of the dropdown
          trigger={<></>}
          onChange={handleDropdownClick}
        />
      )}
    </ButtonGroup>
  );
};

export default ExperienceFormSubmitButton;
