import { getAxios, patchAxios, postAxios } from "../services/Axios";
import {
  getExperiencesUrl,
  getExperienceUrl,
  postExperienceUrl,
  putExperienceUrl,
  searchExperiencesUrl,
  suggestExperiencesUrl,
} from "../constants/url";
import { fromExperienceToMessage, fromMessageToExperience } from "../v2/experience/message";
import { handleError } from "./appDucks";
import { ExperienceField } from "../v2/experience/field";
import { mockExperienceField } from "../v2/experience/__mocks__/field.mock";
import { isAdminUser } from "./userDucks";

const INITIAL_STATE = {
  experience: null,
  experiences: null,
  filterInReview: false,
  filterQuery: "",
  loading: false,
  pageIndex: null,
  pageSize: 200,
  pageToken: null,
  suggestExperienceTitles: null,
  suggestPageSize: 10,
};

const ACTION_TYPES = {
  EXPERIENCES_PATCH_STATE: "EXPERIENCES_PATCH_STATE",
};

export const selectHasMoreExperiences = (state) => {
  return state.experiences.pageToken !== null || state.experiences.pageIndex !== null;
};

export default function reducer(state = INITIAL_STATE, action) {
  if (action.type !== ACTION_TYPES.EXPERIENCES_PATCH_STATE) {
    return state;
  }
  return {
    ...state,
    ...action.payload,
  };
}

export const clearExperience = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: {
      experience: null,
    },
  });
};

export const clearExperiences = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: {
      experiences: null,
      pageIndex: null,
      pageToken: null,
      suggestExperienceTitles: null,
    },
  });
};

const getBusinessId = (state, { allowAll = false } = {}) => {
  // Retrieve the user from the store.
  const user = state.authentication.user;
  // If the user is admin, return "all".
  if (user.isAdmin && allowAll) {
    return "all";
  }
  // Otherwise, return the business id.
  return user.businessId;
};

const startLoading = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: { loading: true },
  });
};

const stopLoading = () => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: { loading: false },
  });
};

export const toggleExperiencesFiltersInReview = () => async (dispatch, getState) => {
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: {
      experience: null,
      experiences: null,
      filterInReview: !getState().experiences.filterInReview,
      pageToken: null,
    },
  });
};

export const loadExperiences = () => async (dispatch, getState) => {
  const { filterQuery } = getState().experiences;
  if (filterQuery) {
    dispatch(searchExperiences());
  } else {
    dispatch(getExperiences());
  }
};

export const searchExperiences = () => async (dispatch, getState) => {
  dispatch(startLoading());
  let root = getState();
  let { filterInReview, filterQuery, experiences, pageIndex, pageSize } = root.experiences;
  try {
    let url = searchExperiencesUrl.replace(":business_id", getBusinessId(root, { allowAll: true }));
    let body = {};
    if (filterInReview) {
      body.state = "ready_for_review";
    }
    if (filterQuery) {
      body.query = filterQuery;
    }
    if (pageIndex) {
      body.page = pageIndex;
    }
    if (pageSize) {
      body.per_page = pageSize;
    }
    let response = await postAxios(url, body);
    if (!experiences) {
      experiences = [];
    } else {
      experiences = [...experiences];
    }
    for (const item of response.data.items) {
      let experience = await fromMessageToExperience(item);
      experiences.push(experience);
    }
    dispatch({
      type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
      payload: {
        experiences: experiences,
        pageIndex: response.data.next_page_index,
      },
    });
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(stopLoading());
  }
};

export const getExperiences = () => async (dispatch, getState) => {
  dispatch(startLoading());
  let root = getState();
  let { filterInReview, experiences, pageSize, pageToken } = root.experiences;
  try {
    let url = getExperiencesUrl.replace(":business_id", getBusinessId(root, { allowAll: true }));
    let params = {};
    if (filterInReview) {
      params.state = "ready_for_review";
    }
    if (pageToken) {
      params.page_token = pageToken;
    }
    if (pageSize) {
      params.page_size = pageSize;
    }
    let response = await getAxios(url, params);
    if (!experiences) {
      experiences = [];
    } else {
      experiences = [...experiences];
    }
    for (const item of response.data.entries) {
      let experience = await fromMessageToExperience(item);
      experiences.push(experience);
    }
    dispatch({
      type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
      payload: {
        experiences: experiences,
        pageToken: response.data.pagination.page_token,
      },
    });
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(stopLoading());
  }
};

export const getExperience = (id) => async (dispatch, getState) => {
  dispatch(startLoading());
  try {
    let url = getExperienceUrl;
    url = url.replace(":business_id", getBusinessId(getState(), { allowAll: true }));
    url = url.replace(":experience_id", id);
    const response = await getAxios(url);
    const experience = await fromMessageToExperience(response.data);
    dispatch({
      type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
      payload: { experience: experience },
    });
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(stopLoading());
  }
};

export const createLocalExperience = () => async (dispatch, getState) => {
  // Declare the experience.
  let experience;
  // If NODE_ENV is development, use the mock experience.
  if (process.env.NODE_ENV === "development") {
    const isAdmin = isAdminUser(getState());
    experience = mockExperienceField(isAdmin);
  } else {
    experience = new ExperienceField();
    experience.value.features.setModified(true);
    experience.value.location.setModified(true);
    experience.value.schedule.value.stayMinutes.setModified(true);
    experience.value.status.value.state.setModified(true);
  }
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: {
      experience: experience,
    },
  });
};

export const createExperience = () => async (dispatch, getState) => {
  dispatch(startLoading());
  const { experience } = getState().experiences;
  try {
    const url = postExperienceUrl.replace(":business_id", getBusinessId(getState()));
    const body = await fromExperienceToMessage(experience, { modifiedOnly: true });
    const response = await postAxios(url, body);
    const responseExperience = await fromMessageToExperience(response.data);
    dispatch(upsertExperienceIntoLocalExperiences(responseExperience));
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(stopLoading());
  }
};

export const updateExperience = () => async (dispatch, getState) => {
  dispatch(startLoading());
  const { experience } = getState().experiences;
  try {
    let url = putExperienceUrl;
    url = url.replace(":business_id", getBusinessId(getState(), { allowAll: true }));
    url = url.replace(":experience_id", experience.value.id.value);
    const body = await fromExperienceToMessage(experience, { modifiedOnly: true });
    const response = await patchAxios(url, body);
    const responseExperience = await fromMessageToExperience(response.data);
    dispatch(upsertExperienceIntoLocalExperiences(responseExperience));
  } catch (error) {
    dispatch(handleError(error));
  } finally {
    dispatch(stopLoading());
  }
};

export const updateLocalExperience = (experience) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: {
      experience: experience,
    },
  });
};

export const updateLocalExperienceValue = (patch) => async (dispatch, getState) => {
  // Retrieve the local experience.
  let { experience } = getState().experiences;
  // Patch the local experience.
  experience = await experience.duplicate().setValue({
    ...experience.value,
    ...patch,
  });
  // Dispatch the patched experience.
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: {
      experience: experience,
    },
  });
};

const upsertExperienceIntoLocalExperiences = (patched) => async (dispatch, getState) => {
  try {
    let payload = { experience: patched };
    let { experiences } = getState().experiences;
    if (experiences) {
      const index = experiences.findIndex((experience) => experience.value.id.value === patched.value.id.value);
      if (index === -1) {
        experiences.push(payload.experience);
      } else {
        experiences[index] = payload.experience;
      }
      payload.experiences = experiences;
    }
    dispatch({
      type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
      payload: payload,
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};

export const updateExperiencesFiltersQuery = (query) => async (dispatch) => {
  dispatch({
    type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
    payload: {
      filterQuery: query,
    },
  });
};

export const suggestExperiences = () => async (dispatch, getState) => {
  let root = getState();
  let { filterQuery, suggestPageSize } = root.experiences;
  try {
    let url = suggestExperiencesUrl.replace(":business_id", getBusinessId(root, { allowAll: true }));
    let params = {
      query: filterQuery,
    };
    if (suggestPageSize) {
      params.per_page = suggestPageSize;
    }
    let response = await postAxios(url, params);
    let suggestExperienceTitles = response.data;
    dispatch({
      type: ACTION_TYPES.EXPERIENCES_PATCH_STATE,
      payload: {
        suggestExperienceTitles: suggestExperienceTitles,
      },
    });
  } catch (error) {
    dispatch(handleError(error));
  }
};
