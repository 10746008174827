import React, { useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import { Chart } from "react-google-charts";
import AppBase from "../../components/AppBase";
import { Button, Divider, Dropdown, Grid, Header } from "semantic-ui-react";
import { ReactComponent as CloseIcon } from "../../assets/Icons/back-experience.svg";
import * as ROUTES from "../../constants/routes";
import "./Dashboard.css";
import { useDispatch, useSelector } from "react-redux";
import { getExperience } from "../../redux/experienceDucks";

const ExperienceDashboard = (props) => {
  const experience = useSelector((store) => store.experience.experience);
  const dispatch = useDispatch();

  const optionsFilter = [
    { key: 1, text: "Today", value: 1 },
    { key: 2, text: "Yesterday", value: 2 },
    { key: 3, text: "This week", value: 3 },
    { key: 4, text: "Last week", value: 4 },
    { key: 5, text: "Last month", value: 5 },
    { key: 6, text: "This year", value: 6 },
  ];

  const dataGeo = [
    ["Country", "Popularity"],
    ["Germany", 200],
    ["United States", 300],
    ["Brazil", 400],
    ["Canada", 500],
    ["France", 600],
    ["RU", 700],
  ];

  const dataTrafic = [
    ["Month", "Visitas", "Agregados"],
    ["Feb", 1300, 400],
    ["Mar", 1170, 460],
    ["Oct", 590, 1120],
    ["Dic", 830, 540],
  ];

  const loader = <div>Loading Chart</div>;

  const width = "100%";
  const height = "400px";

  useEffect(() => {
    const { id } = props.match.params;
    if (id) dispatch(getExperience(id));
  }, [dispatch, props.match.params]);

  return (
    <AppBase>
      <Grid.Column width={16} style={{ paddingTop: 0 }}>
        <Button
          icon
          labelPosition="left"
          className="dashboard-close-button"
          as={Link}
          to={ROUTES.EXPERIENCE_RETRIEVE.replace(":id", experience.id)}
        >
          atrás
          <i aria-hidden="true" className="icon dashboard-close-button-icon">
            <CloseIcon />
          </i>
        </Button>
        <Grid>
          <Grid.Column computer={8} mobile={16} style={{ paddingRight: 0, paddingLeft: 0 }}>
            <Header className="dashboard-title">Mis estadísticas</Header>
            <Header className="dashboard-sub-title-1">{experience.title}</Header>
          </Grid.Column>
          <Grid.Column
            computer={8}
            mobile={16}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "flex-end",
            }}
          >
            <Dropdown placeholder="Filtros" options={optionsFilter} selection />
          </Grid.Column>
        </Grid>
        <Grid.Column width={16}>
          <Header className="dashboard-sub-title">Tráfico</Header>
          <Divider className="dashboard-divider" />
          <Chart
            chartType="LineChart"
            width={width}
            height={height}
            data={dataTrafic}
            options={{
              title: "Company Performance",
              curveType: "function",
              legend: { position: "bottom" },
              backgroundColor: "#eee4cf",
              animation: {
                duration: 2000,
                easing: "inAndOut",
                startup: true,
              },
              colors: ["#1a8e46", "#e54e26"],
            }}
            loader={loader}
          />
          <Header className="dashboard-sub-title">Geográfico</Header>
          <Divider className="dashboard-divider" />
          <Chart
            chartType="GeoChart"
            width="100%"
            height="400px"
            data={dataGeo}
            options={{
              backgroundColor: "#eee4cf",
              datalessRegionColor: "#FFF",
              defaultColor: "#F12",
              colorAxis: { colors: ["#31bbac", "#1a8e46", "#e54e26"] },
            }}
            loader={loader}
          />
        </Grid.Column>
      </Grid.Column>
    </AppBase>
  );
};

export default withRouter(ExperienceDashboard);
