import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import * as ROUTES from "../constants/routes";

export const RouteRegisteredUser = ({ component, path, ...rest }) => {
  const user = useSelector((store) => store.authentication.user);
  if (user && user.token) {
    return <Route component={component} path={path} {...rest} />;
  }
  return <Redirect to={ROUTES.LOGIN} {...rest} />;
};
