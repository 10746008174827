import axios from "axios";
import { postDistanceMatrixUrl } from "../constants/url";
import { VALIDATION_ANCHOR_LATITUDE, VALIDATION_ANCHOR_LONGITUDE } from "../constants/location";

const isReachable = async (latitude, longitude) => {
  try {
    const response = await axios.post(postDistanceMatrixUrl, {
      origins: [
        {
          latitude: VALIDATION_ANCHOR_LATITUDE,
          longitude: VALIDATION_ANCHOR_LONGITUDE,
        },
        {
          latitude: latitude,
          longitude: longitude,
        },
      ],
    });
    return response.status === 200;
  } catch (error) {
    return false;
  }
};

export const DistanceMatrix = {
  isReachable,
};
