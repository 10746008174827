import React, { useEffect, useState } from "react";
import { Card, Image } from "semantic-ui-react";
import { useSelector } from "react-redux";

export const ExperienceFormPreviewImages = () => {
  // A const for retrieving the images of the experience from the store.
  const images = useSelector((state) => state.experiences.experience.value.images);

  // A state variable for the selected image.
  const [selectedImage, setSelectedImage] = useState({
    index: undefined,
    url: undefined,
  });

  // An effect for updating the selected image when the imageUrls change.
  useEffect(() => {
    // If the image URLs are empty, set the selected image to undefined.
    if (images.value.length === 0) {
      setSelectedImage({
        index: undefined,
        url: undefined,
      });
      return;
    }
    // If the selected image is undefined, set the selected image to the first image.
    if (selectedImage.index === undefined) {
      setSelectedImage({
        index: 0,
        url: images.value[0].pictureUrl,
      });
      return;
    }
    // If the index corresponds to the URL, do nothing.
    if (images.value[selectedImage.index].pictureUrl === selectedImage.url) {
      return;
    }
    // If the selected URL is not in the image URLs, set the selected image to the first image.
    if (!images.value.map((image) => image.pictureUrl).includes(selectedImage.url)) {
      setSelectedImage({
        index: 0,
        url: images.value[0].pictureUrl,
      });
      return;
    }
    // If the selected URL is in the image URLs, set the selected index to the index of the URL.
    setSelectedImage({
      index: images.value.map((image) => image.pictureUrl).indexOf(selectedImage.url),
      url: selectedImage.url,
    });
  }, [images.value]);

  function renderSelectedImage() {
    return (
      <div
        style={{
          display: "flex",
          flex: "1",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          borderTopLeftRadius: "12px",
          borderTopRightRadius: "12px",
        }}
      >
        <Image
          src={selectedImage.url}
          centered
          style={{
            flexShrink: 3,
            maxWidth: "100%",
            maxHeight: "400px",
            objectFit: "contain",
          }}
        />
      </div>
    );
  }

  function renderAllImages() {
    return (
      <div
        style={{
          display: "flex",
          padding: "12px",
          justifyContent: "center",
          flexBasis: "25%",
        }}
      >
        <Card.Group
          itemsPerRow={5}
          style={{
            justifyContent: "center",
          }}
        >
          {images.value
            .filter((image) => !image._destroy)
            .map((image, index) => {
              return (
                <Card
                  raised
                  key={index}
                  onClick={() =>
                    setSelectedImage({
                      index: index,
                      url: image.pictureUrl,
                    })
                  }
                  style={{
                    height: "60px",
                    width: "60px",
                  }}
                >
                  <img
                    src={image.pictureUrl}
                    style={{
                      height: "100%",
                      width: "100%",
                      objectFit: "cover",
                    }}
                  />
                </Card>
              );
            })}
        </Card.Group>
      </div>
    );
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          margin: "12px",
          backgroundColor: "lightgrey",
          borderRadius: "12px",
        }}
      >
        {renderSelectedImage()}
        {renderAllImages()}
      </div>
    </>
  );
};
