import React, { useEffect, useState } from "react";
import { Message, TextArea } from "semantic-ui-react";
import { useDispatch, useSelector } from "react-redux";
import { updateLocalExperienceValue } from "../../../../redux/experienceDucks";

export const ExperienceFormDescription = () => {
  const [descriptionText, setDescriptionText] = useState("");
  const description = useSelector((state) => state.experiences.experience.value.description);
  const dispatch = useDispatch();

  const handleChange = async (event, data) => {
    setDescriptionText(data.value);
    await description.setValue(data.value);
    await dispatch(updateLocalExperienceValue({ description: description }));
  };

  useEffect(() => {
    if (description === null) {
      return;
    }
    setDescriptionText(description.value);
  });

  return (
    <>
      {description.hasError() && (
        <Message error visible>
          {description.error}
        </Message>
      )}
      <TextArea onChange={handleChange} placeholder="Tell us more" rows={10} value={descriptionText} />
    </>
  );
};
