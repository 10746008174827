import React, { useState } from "react";
import AppBase from "../../components/AppBase/AppBase";
import ExperienceCardList from "./components/CardList/CardList";

export const Console = () => {
  const [openMessage, setOpenMessage] = useState(false);

  return (
    <>
      <AppBase style={{ filter: `${openMessage ? "brightness(.6)" : "brightness(1)"}` }}>
        <ExperienceCardList setOpenMessage={setOpenMessage.bind(this, true)} />
      </AppBase>
    </>
  );
};
