import React from "react";
import { useSelector } from "react-redux";
import { Grid, Header } from "semantic-ui-react";
import FacebookButton from "../../../../components/FacebookButton";
import "./RegisterInfo.css";

const RegisterInfo = () => {
  const activeStep = useSelector((store) => store.authentication.activeStep);

  return (
    <Grid.Column>
      <Grid.Row>
        <Header className="register-info-title">
          {activeStep === 2
            ? "Welcome! Take advantage and highlight your business among travelers"
            : "Connect with travelers and highlight your business"}
        </Header>
      </Grid.Row>
      <Grid.Row className="register-info-facebook-container">{activeStep !== 2 && <FacebookButton />}</Grid.Row>
    </Grid.Column>
  );
};

export default RegisterInfo;
