export const Booleans = {
  atLeastOne: (values) => {
    for (let i = 0; i < values.length; i++) {
      if (values[i]) {
        return true;
      }
    }
    return false;
  },
};
