import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { RouteRegisteredUser } from "./services/Middleware";
import Login from "./views/Login/Login";
import ForgotPassword from "./views/ForgotPassword/ForgotPassword";
import Register from "./views/Register/Register";
import UserProfile from "./views/Profile";
import EmailVerified from "./views/EmailVerified";
import { Console } from "./views/Console/Console";
import ExperienceDashboard from "./views/Dashboard";
import Loading from "./components/Loading";
import * as ROUTES from "./constants/routes";
import "./App.css";
import ExperienceForm from "./views/Console/views/ExperienceForm/ExperienceForm";
import { Helmet } from "react-helmet";
import { AppMessage } from "./components/AppMessage";

const App = () => {
  const [initialScale, setInitialScale] = useState(1.0);

  useEffect(() => {
    if (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/iPhone|iPad/i)) {
      setInitialScale(0.1);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta name="viewport" content={`width=1280, initial-scale=${initialScale}, maximum-scale=1.0`} />
      </Helmet>
      <Loading />
      <AppMessage />
      <Router>
        <Switch>
          <Route exact path={ROUTES.REGISTER} component={Register} />
          <Route exact path={ROUTES.LOGIN} component={Login} />
          <Route exact path={[ROUTES.ROOT, ROUTES.LOGIN]} component={Login} />

          <Route exact path={ROUTES.PASSWORD_FORGOT} component={ForgotPassword} />

          <RouteRegisteredUser exact path={ROUTES.PROFILE} component={UserProfile} />

          <RouteRegisteredUser exact path={ROUTES.CONSOLE} component={Console} />

          <RouteRegisteredUser exact path={ROUTES.EXPERIENCE_CREATE} component={ExperienceForm} />
          <RouteRegisteredUser exact path={ROUTES.EXPERIENCE_UPDATE} component={ExperienceForm} />
          <RouteRegisteredUser exact path={ROUTES.EXPERIENCE_DASHBOARD} component={ExperienceDashboard} />

          <Route exact path={ROUTES.CONSUMER_EMAIL_VERIFIED} component={EmailVerified} />
        </Switch>
      </Router>
    </>
  );
};

export default App;
