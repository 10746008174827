import React, { useEffect, useState } from "react";
import { Button, Form, Grid, Header, Icon } from "semantic-ui-react";
import FormInput from "../../../components/FormInput";
import { ReactComponent as LockEmptyIcon } from "../../../assets/Icons/lock-empty.svg";
import { ReactComponent as LockCompleteIcon } from "../../../assets/Icons/lock-complete.svg";
import { ReactComponent as CancelIcon } from "../../../assets/Icons/cancel.svg";
import { ReactComponent as CheckIcon } from "../../../assets/Icons/check.svg";
import "./FormDataPasswordReset.css";
import { validatePasswordRequirements } from "../../../v2/password";
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "../../../redux/userDucks";

const FormDataPasswordReset = ({ token }) => {
  const [password, setPassword] = useState("");
  const [confirmationPassword, setConfirmationPassword] = useState("");
  const [error, setError] = useState({});
  const resetPasswordSucceeded = useSelector((store) => store.authentication.resetPassword);
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (error.isPasswordComplete) {
      dispatch(resetPassword(password, confirmationPassword, token));
    }
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmationPasswordChange = (event) => {
    setConfirmationPassword(event.target.value);
  };

  useEffect(() => {
    const error = validatePasswordRequirements(password, confirmationPassword);
    setError(error);
  }, [password, confirmationPassword]);

  useEffect(() => {
    if (resetPasswordSucceeded) {
      window.location.href = "/login";
    }
  }, [resetPasswordSucceeded]);

  return (
    <Form className="form-login-forgot-password-reset" onSubmit={handleSubmit}>
      <Header className="title-form-login-forgot-password-reset">Restore password</Header>
      <FormInput
        name="password"
        placeholder="New password"
        type="password"
        IconInput={!password ? LockEmptyIcon : LockCompleteIcon}
        handleOnChange={handlePasswordChange}
      />
      <FormInput
        name="confirmationPassword"
        placeholder="Confirm new password"
        type="password"
        IconInput={!confirmationPassword ? LockEmptyIcon : LockCompleteIcon}
        handleOnChange={handleConfirmationPasswordChange}
      />
      <Grid.Row className="login-forgot-password-check-list">
        <Header className="login-forgot-password-check-item">
          <i aria-hidden="true" className="icon">
            {error.isPasswordLength ? <CheckIcon /> : <CancelIcon />}
          </i>
          Must be at least 8 characters
        </Header>
        <Header className="login-forgot-password-check-item">
          <i aria-hidden="true" className="icon">
            {error.isPasswordCapitalLetter ? <CheckIcon /> : <CancelIcon />}
          </i>
          Must include a capital letter
        </Header>
        <Header className="login-forgot-password-check-item">
          <i aria-hidden="true" className="icon">
            {error.isPasswordNumber ? <CheckIcon /> : <CancelIcon />}
          </i>
          Must include a number
        </Header>
        <Header className="login-forgot-password-check-item">
          <i aria-hidden="true" className="icon">
            {error.isPasswordComplete ? <CheckIcon /> : <CancelIcon />}
          </i>
          Must confirm password
        </Header>
      </Grid.Row>
      <Grid.Row className="login-forgot-password-container-password-button">
        <Button
          icon
          labelPosition="right"
          style={{
            width: "214px",
            height: "51px",
            borderRadius: "35px",
            fontSize: "16px",
            fontWeight: "500",
            fontStretch: "normal",
            fontStyle: "normal",
            lineHeight: "1.13",
            letterSpacing: "normal",
            display: "block",
            marginLeft: "auto",
            marginRight: "auto",
            backgroundColor: "var(--principal-color-page)",
            color: "#FFFFFF",
          }}
        >
          Restore
          <Icon
            name="play"
            size="small"
            style={{
              backgroundColor: "transparent",
              right: "28px",
              paddingLeft: "unset",
              paddingRight: "0.9em",
              color: "#FFFFFF",
            }}
            className="login-forgot-password-icon-button"
          />
        </Button>
      </Grid.Row>
    </Form>
  );
};

export default FormDataPasswordReset;
